<template>
  <pageContainer>
    <div class="home-page-view-top">
      <img class="top-banner" :src="`${$COS}index_banner.png`" alt="">
      <div class="content-view-bg">
        <div class="content-view">
          <div class="top-title">
            <p>{{ $t('home.text1') }}</p>
            <p>{{ $t('home.text2') }}</p>
          </div>
          <div class="top-intro">
            <div v-for="(item,index) in topIntroList" :key="index" class="intro-item">
              <div>
                <p class="title">{{item.title}}</p>
                <p class="text">{{item.text}}</p>
              </div>
              <img class="icon" :src="item.icon" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="home-page-view-content-bg">
      <div class="home-page-view-content">
        <div class="content-study content-item">
          <div class="title-view">
            <img class="title-icon" src="@/assets/icon_title@3x.png" alt="">
            <p>{{ $t('home.text3') }}</p>
          </div>
          <div class="study-intro">
            <div class="study-intro-left">
              <p class="title">{{ $t('home.text4') }}</p>
              <p class="desc">{{ $t('home.text5') }}</p>
              <p class="desc">{{ $t('home.text6') }}</p>
            </div>
            <div class="study-intro-right">
              <div class="right-item">
                <p class="title">{{ $t('home.text7') }}</p>
                <div class="desc">
                  <p>● {{ $t('home.text8') }}</p>
                  <p>● {{ $t('home.text9') }}</p>
                  <p>● {{ $t('home.text10') }}</p>
                  <p>● {{ $t('home.text11') }}</p>
                </div>
              </div>
              <div class="right-item">
                <p class="title">{{ $t('home.text12') }}</p>
                <div class="desc">
                  <p>● {{ $t('home.text13') }} </p>
                  <p>● {{ $t('home.text14') }} </p>
                  <p>● {{ $t('home.text15') }} </p>
                  <p>● {{ $t('home.text16') }} </p>
                  <p>● {{ $t('home.text17') }}</p>
                  <p class="tip">
                    {{ $t('home.text18') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-before content-item">
          <div class="title-view">
            <img class="title-icon" src="@/assets/icon_title@3x.png" alt="">
            <p>{{ $t('home.text19') }}</p>
          </div>
          <div class="before-intro">
            <div class="tips">
              <p>{{ $t('home.text20') }}</p>
              <p>{{ $t('home.text21') }}</p>
            </div>
            <div class="intro-view">
              <div class="intro-item" v-for="(item,index) in beforeIntroList" :key="index">
                <img class="intro-img" :src="item.img" alt="">
                <div class="intro-text" :style="{
                  background: item.bgColor
                }">
                  <p class="intro-text-title">{{item.title}}</p>
                  <div class="intro-text-content">
                    <p v-for="textItem in item.textList" :key="textItem">{{textItem}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-reach content-item">
          <div class="title-view">
            <img class="title-icon" src="@/assets/icon_title@3x.png" alt="">
            <p>{{ $t('home.text22') }}</p>
          </div>
          <div class="content-reach-view">
            <div class="content-reach-top">
              <img class="reach-img" :src="`${$COS}index_landing@3x.png`" alt="">
              <div class="text-view">
                <p>{{ $t('home.text23') }}</p>
                <p>{{ $t('home.text24') }}</p>
                <img class="tip-icon" src="@/assets/index_pattern2@3x.png" alt="">
              </div>
            </div>
            <div class="reach-info-view">
              <div class="reach-info-item" v-for="(item,index) in reachInfoList" :key="index">
                <img :src="item.icon" alt="">
                <div class="reach-info-item-text">
                  <div class="reach-info-item-text-title">{{item.title}}</div>
                  <div class="reach-info-item-text-desc">
                    <p v-for="textItem in item.textList" :key="textItem"> {{textItem}} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="content-reach content-item content-family">
          <div class="title-view">
            <img class="title-icon" src="@/assets/icon_title@3x.png" alt="">
            <p>{{ $t('home.text25') }}</p>
          </div>
          <div class="content-reach-view">
            <div class="content-reach-top">
              <div class="text-view">
                <p>{{ $t('home.text26') }}</p>
                <p>{{ $t('home.text27') }}</p>
                <img class="tip-icon" src="@/assets/index_pattern1@3x.png" alt="">
              </div>
              <img class="reach-img" :src="`${$COS}index_settle@3x.png`"  alt="">
            </div>
            <div class="reach-info-view">
              <div class="reach-info-item" v-for="(item,index) in familyInfoList" :key="index">
                <img :src="item.icon" alt="">
                <div class="reach-info-item-text">
                  <div class="reach-info-item-text-title">{{item.title}}</div>
                  <div class="reach-info-item-text-desc">
                    <p v-for="textItem in item.textList" :key="textItem"> {{textItem}} </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> 
  </pageContainer>
</template>


<script setup>
import {ref, getCurrentInstance} from "vue"
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const instance = getCurrentInstance()
const COS = instance.appContext.config.globalProperties.$COS

const topIntroList = ref([
  {
    title: t('home.text28'),
    text: t('home.text29'),
    icon: require('@/assets/index_icon1@3x.png'),
  },
  {
    title: t('home.text30'),
    text: t('home.text31'),
    icon: require('@/assets/index_icon2@3x.png'),
  },
  {
    title: t('home.text32'),
    text: t('home.text33'),
    icon: require('@/assets/index_icon3@3x.png'),
  },
  {
    title: t('home.text34'),
    text: t('home.text35'),
    icon: require('@/assets/index_icon4@3x.png'),
  },
  {
    title: t('home.text36'),
    text: t('home.text37'),
    icon: require('@/assets/index_icon5@3x.png'),
  }
])


const beforeIntroList = ref([
{
    title: t('home.text38'),
    img: `${COS}index_before1@3x.png`,
    bgColor: '#16318C',
    textList: [
      t('home.text39'),
      t('home.text40'),
      t('home.text41'),
    ]
  },
  {
    title: t('home.text42'),
    img: `${COS}index_before2@3x.png`,
    bgColor: '#FF5B33',
    textList: [
      t('home.text43'),
      t('home.text44')
    ]
  },
  {
    title: t('home.text45'),
    img: `${COS}index_before3@3x.png`,
    bgColor: '#16318C',
    textList: [
      t('home.text46'),
      t('home.text47')
    ]
  }
])

const reachInfoList = ref([
  {
    title: t('home.text48'),
    icon: require('@/assets/index_landing_icon1@3x.png'),
    textList: [
      t('home.text49'),
      t('home.text50'),
      t('home.text51'),
      t('home.text52'),
    ]
  },
  {
    title: t('home.text53'),
    icon: require('@/assets/index_landing_icon2@3x.png'),
    textList: [
      t('home.text54'),
      t('home.text55'),
      t('home.text56'),
      t('home.text57'),
    ]
  },
  {
    title: t('home.text58'),
    icon: require('@/assets/index_landing_icon5@3x.png'),
    textList: [
      t('home.text59'),
      t('home.text60'),
      t('home.text61'),
      t('home.text62')
    ]
  }
])


const familyInfoList = ref([
  {
    title: t('home.text63'),
    icon: require('@/assets/index_settle_icon1@3x.png'),
    textList: [
      t('home.text64'),
      t('home.text65'),
      t('home.text66'),
    ]
  },
  {
    title: t('home.text67'),
    icon: require('@/assets/index_settle_icon2@3x.png'),
    textList: [
      t('home.text68'),
      t('home.text69'),
    ]
  },
  {
    title: t('home.text70'),
    icon: require('@/assets/index_settle_icon3@3x.png'),
    textList: [
      t('home.text71'),
      t('home.text72'),
      t('home.text73')
    ]
  }
])




</script>
<style lang="scss" scoped>


.home-page-view-top{
  position: relative;
  margin-bottom: 184px;
  .top-banner{
    min-height: 610px;
    width: 100%;
    display: block;
  }
  .content-view-bg{
    position: absolute;
    bottom: -64px;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    .content-view{
      width: 1300px;
      .top-title{
        text-align: left;
        font-weight: 700;
        font-size: 64px;
        color: #FFFFFF;
      }
      .top-intro{
        margin-top: 186px;
        display: flex;
        .intro-item{
          padding-top: 16px;
          padding-left: 20px;
          height: 112px;
          background: #FFFFFF;
          box-shadow: 0 8px 16px 0 #0000000d;
          border-radius: 16px;
          flex: 1;
          margin-right: 25px;
          text-align: left;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .title{
            font-weight: 700;
            font-size: 24px;
            color: #000000;
          }
          .text{
            font-weight: 400;
            font-size: 16px;
            color: #000000;
          }
          .icon{
            align-self: flex-end;
            height: 46px;
          }
        }
        .intro-item:last-child{
          margin-right: 0;
        }
      }
    }
  }
}
.home-page-view-content-bg{
  display: flex;
  justify-content: center;
}
.home-page-view-content{
  display: flex;
  width: 1300px;
  flex-direction: column;
  .content-item{
    margin-bottom: 120px;
    .title-view{
      font-weight: 700;
      font-size: 40px;
      display: flex;
      .title-icon{
        margin-right: 24px;
        width: 16px;
        display: block;
      }
    }
  }
  
  .content-study{
    width: 100%;
    .study-intro{
      border-radius: 16px;
      margin-top: 30px;
      width: 100%;
      height: 606px;
      background: url('https://qtiao-web-1258997378.cos.accelerate.myqcloud.com/kitointl/PC/index_bg_immigrate.png');
      background-size: 100% 100%;
      display: flex;
      .study-intro-left{
        width: 550px;
        text-align: left;
        padding: 50px 40px;
        align-self: end;
        .title{
          font-weight: 700;
          font-size: 24px;
          color: #FFFFFF;
          margin-bottom: 35px;
        }
        .desc{
          font-weight: 500;
          font-size: 14px;
          color: #FFFFFF;
          margin-bottom: 17px;
          &:last-child{
            margin-bottom: 0;
          }
        }
      }
      .study-intro-right{
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 20px;
        align-items: flex-end;
        text-align: left;
        .right-item{
          border-radius: 8px;
          background: #FFFFFFF5;
          box-shadow: 0 8px 16px 0 #00000014;
          width: 390px;
          padding: 20px 20px 25px;
          margin-bottom: 20px;
          background-image: url('~@/assets/index_icon_edu1.png');
          background-repeat: no-repeat;
          background-size: 196px 106px;
          background-position: right bottom;
          &:last-child{
            background-image: url('~@/assets/index_icon_edu2.png');
            margin-bottom: 0;
            flex: 1;
          }
          .title{
            font-weight: 700;
            font-size: 24px;
            color: #000000;
          }
          .desc{
            margin-top: 30px;
            font-weight: 400;
            font-size: 14px;
            color: #000000;
            line-height: 30px;
            .tip{
              font-weight: 400;
              font-size: 12px;
              line-height: 18px;
              padding-left: 10px;
              opacity: 0.6;
            }
          }
        }
        
      }
    }
  }

  .content-before{
    .before-intro{
      .tips{
        color: #000000;
        text-align: left;
        font-weight: 400;
        font-size: 14px;
        line-height: 32px;
        margin: 30px 0;
      }
      .intro-view{
        display: flex;
        .intro-item{
          flex: 1;
          margin-right: 20px;
          display: flex;
          flex-direction: column;
          border-radius: 16px;
          overflow: hidden;
          &:last-child{
            margin-right: 0;
          }
          .intro-img{
            width: 100%;
            display: block;
          }
          .intro-text{
            flex: 1;
            padding: 40px;
            color: #FFFFFF;
            text-align: left;
            .intro-text-title{
              font-size: 24px;
              font-weight: bold;
            }
            .intro-text-content{
              font-size: 14px;
              line-height: 32px;
              margin-top: 30px;
            }
          }
        }
      }
    }
  }

  .content-reach{
    .content-reach-view{
      margin-top: 30px;
      .content-reach-top{
        display: flex;
        justify-content: space-between;
        .reach-img{
          height: 220px;
          display: block;
        }
        .text-view{
          background: #FF5B33;
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          font-weight: 400;
          font-size: 14px;
          color: #FFFFFF;
          line-height: 32px;
          text-align: left;
          padding: 0 40px;
          border-radius: 16px;
          margin-left: 20px;
          position: relative;
          .tip-icon{
            position: absolute;
            width: 60px;
            height: 60px;
            top: -1px;
            right: -1px;
          }
        }
      }
      .reach-info-view{
        margin-top: 80px;
        display: flex;
        justify-content: space-between;
        .reach-info-item{
          display: flex;
          flex: 1;
          >img{
            width: 96px;
            height: 96px;
            display: block;
          }
          .reach-info-item-text{
            text-align: left;
            margin-left: 20px;
            .reach-info-item-text-title{
              font-weight: 700;
              font-size: 24px;
            }
            .reach-info-item-text-desc{
              font-size: 14px;
              line-height: 32px;
              margin-top: 30px;
            }
          }
        }
      }
    }
  }

  .content-family .content-reach-view .content-reach-top .text-view{
    background: #16318C;
    margin-left: 0;
    margin-right: 20px;
  }
}


</style>
