// 繁体

// 移民咨询
import { nonimmigrant, immigrant, others } from "./article-hant"
// 验证码
import { captchaExplain } from "./code-hant"

export default {
  navBar:{
    title1: '首頁',
    title2: '關於我們',
    title3: '移民咨詢',
    title4: '聯繫我們',
    title5: '費用及項目進度',
    title6: '香港身份'
  },
  home: {
    text1: '美國移民/教育/落地/安居',
    text2: '服務專家',
    text3: '留學移民',
    text4: '服務介紹',
    text5: '教育：美國匯聚世界頂級教育資源，教育體系的最大優勢在於其靈活性和創新性。我們將提供完善的本地學校及高校的福利申請、擇校入學服務，助力您孩子的教育成長之路。',
    text6: '移民：美國擁有強大的經濟體系和高競爭力的市場以及完善的社會保障體系。我們能夠為您提供量身定製的移民及簽證全流程服務，幫助您開啟自己的新生活。',
    text7: '教育規劃',
    text8: '中小學擇校入學',
    text9: '高等院校擇校申請',
    text10: '轉學及簽證辦理',
    text11: '學生監護',
    text12: '移民服務',
    text13: '一站式移民/簽證申請規劃',
    text14: '移民簽證（EB-1/EB-2/EB-3/EB-5)',
    text15: '非移民簽證(L-1/E-2/O-1)',
    text16: '家庭移民簽證（身份調整/親屬移民）',
    text17: '其他服務',
    text18: '面試準備/材料審核/領館處理程序/豁免申請/綠卡更新/入籍申請/移民法庭聽證會',
    text19: '行前服務',
    text20: '中美文化環境存在著巨大差異，在您移居美國前需了解美國生活習慣以融入當地社會。',
    text21: '我們將提供完善的出國前準備服務，並為您準備實用備忘清單，助您順利抵達異國生活並收獲新機遇。',
    text22: '落地服務',
    text23: 'KITO INTERNATIONAL，作為香港領先的國際移民安居服務提供商，在美國本土經營多年。',
    text24: '我們在多個州設立了專業的辦事處，提供一系列細致周到的落地服務，旨在幫助您輕松融入當地環境。',
    text25: '安家服務',
    text26: '借助在美國本土的豐富資源，我們將為您提供全方位的租房買房、租車賣車以及就業等服務',
    text27: '確保您在異國他鄉能夠安心安頓，無憂生活。',
    text28: '40家',
    text29: '美國本土落地安居服務公司',
    text30: '15年',
    text31: '國際教育及移民服務經驗',
    text32: '15000+',
    text33: '已服務客戶',
    text34: '120+',
    text35: '已合作知名品牌',
    text36: '300+',
    text37: '專業團隊成員',
    text38: '行前輔導',
    text39: '城市環境及文化介紹',
    text40: '美國教育及移民情況介紹',
    text41: '基本生活註意事項提醒',
    text42: '入境準備',
    text43: '必需物品清單準備',
    text44: '國際物流支持',
    text45: '行程規劃',
    text46: '入境文件準備',
    text47: '本土酒店預訂',
    text48: '落地到家',
    text49: '本地接機',
    text50: '銀行開戶',
    text51: '機號辦理',
    text52: '線上社群答疑',
    text53: '健康保障',
    text54: '社保卡(SSN)申請',
    text55: '本地醫療保險推薦',
    text56: '商業保險購買協助',
    text57: '家庭醫生介紹',
    text58: '財稅支持',
    text59: '會計服務',
    text60: '保險經紀人推薦',
    text61: '稅務提醒',
    text62: '理財規劃',
    text63: '安家協助',
    text64: '租房購房支持',
    text65: '物業、水電煤氣、網絡開戶服務',
    text66: '家政服務及搬家服務等',
    text67: '車輛交通',
    text68: '車輛購買、車險辦理',
    text69: '駕照申請',
    text70: '新移民就業',
    text71: '就業指導',
    text72: '創業協助',
    text73: '稅務規劃',
  },
  about: {
    text1: '關於我們',
    text2: 'KITO INTERNATIONAL是一家總部設在香港、成立超過15年的國際安居服務公司。我們專註於為高凈值人群提供多元化的涉外服務，包括資產處置、移民規劃等多個方面。作為香港首屈一指的國際安居服務供應商，我們與各行各業的客戶建立了穩固且長期的合作關系，涵蓋金融機構、房地產開發商、餐飲行業、投資基金、高等教育機構、科技創新企業、醫藥生物公司以及酒店管理集團等多個領域的高凈值客戶。目前，我們的業務網絡遍布全球，除了位於香港的總部外，還在美國、新加坡和深圳設有辦公室，以便更加精準地滿足客戶的多元化需求。',
    text3: '部分機構及品牌',
    text4: '專業團隊',
    text5: '我們凝聚了一支逾300位具備全球視野、深厚行業經驗和卓越專業能力的精英團隊，他們橫跨多個領域，專註於為個人及企業客戶打造一站式、個性化定製的海外安居服務，確保每位客戶都能體驗到無微不至的關懷。無論是資產配置、海外移民，還是應對安居過程中的挑戰，我們始終將客戶的利益放在第一位，致力於滿足他們的獨特需求和期望。',
    text6: '獎項或協會成員',
  },
  contact: {
    text1: '聯繫我們',
    text2: '電話聯絡',
    text3: '北京時間週一到週五 9:30AM - 6:00PM',
    text4: '聯繫郵箱',
    text5: '週一到週五 9:30AM - 6:00PM'
  },
  schedule: {
    text1: '費用及項目進度',
    text2: '在線查詢',
    text3: '請輸入你的項目查詢碼',
    text4: '聯繫律師或項目經理獲取查詢碼',
    text5: '請輸入正確的查詢碼以查詢',
    text6: '查詢碼錯誤'
  },
  consult: {
    text1: '移民咨詢',
    text2: '非移民簽證',
    text3: '移民簽證',
    text4: '其他',
  },
  nonimmigrant,
  immigrant,
  others,
  captchaExplain
}