export const captchaExplain = [
  {
    key: 'CH64554',
    title: '美國移民EB1/EB2全案委託服務函',
    content:
    `
      <div class="paragraph bold">服務內容：</div>
      <div class="paragraph">
        <p class="bold">前期：確定申請方案</p>
        <p>1.核對客戶提供的資訊、材料，通過雙方溝通、網上資源等方式收集有用資料，並篩選符合美國移民局要求的申請資訊</p>
        <p>2.結合行業背景、申請人的個人具體情況，制定申請方向及資料清單</p>
        <p>3.根據個人具體情況，製作申請時間计划（期间需要客户配合计划进行资料准备）</p>
        <p>4.根據美國移民局最新的移民申請綱領和決定，以及您個人的具體情況，選擇適用於你的申請的正確方向</p>
      </div>

      <div class="paragraph">
        <p class="bold">中期：文書撰寫及申請材料整理</p>
        <p>5.核心文書（個人陳述、簡歷、推薦信）</p>
        <p>個人陳述</p>
        <p>a）指導您提供能佐證您個人能力與成就的資訊，並撰寫您的個人陳述/赴美聲明</p>
        <p>簡歷</p>
        <p>a）結合您的情況，撰寫個性化簡歷（如需）</p>
        <p>推薦信相關：</p>
        <p>a)指導您如何找適用於您的推薦人</p>
        <p>b)根據您的專業領域，為您撰寫“貼身”推薦信，重點突出您的傑出才能，增強推薦信的說服力</p>
        <p>c)經您審核後，再次對您的推薦信進行修改潤色，並最終定稿</p>
        <p>6.撰寫申請相關條件文書材料(如赴美商業計畫書、工作證明信、專利轉化證明……)</p>
        <p>7.在準備過程中，如遇到申請材料丟失、無法提供等困難，我們將結合實際實際情況為您提供可行的解決方案，以確保您的美國申請可以順利遞交。</p>
        <p>8.跟進申請材料的翻譯及校對（通常用時需1-2個月，具體要看申請資料的數量）</p>
        <p>9.在整合材料完成後，我們的律師會起草一封強有力的申請信，根據您所提供的成就和貢獻，歸納總結證據，列舉事實，涵蓋您所有的證明的各個要點，在關鍵的地方進行有效辯論，充分解釋這些材料為何能夠說明您符合EB1/EB2的標準</p>
        <p>10.填寫所有相關的移民申請表格</p>
        <p>11.整理材料+製作目錄+列印所有材料</p>
      </div>

      <div class="paragraph">
        <p class="bold">後期：申請跟進+轉換身份</p>
        <p>申請跟進</p>
        <p>12.在審核全部的申請材料後，將完整的申請材料提交給移民局</p>
        <p>13.定期查看您的案件申請進展，並與您同步結果。</p>
        <p>14.若移民局發出了補充材料通知，我們將結合補料要求及您的情況制定相應的補料方案、準備好補充文件，並在規定時間內遞交補料。</p>
      </div>

      <div class="paragraph">
        <p>轉換身份</p>
        <p>15.獲批之後，協助你進行NVC面試準備、體檢預約、身份轉換及其他相關服務。</p>
        <p>16.提供入境指導、綠卡郵寄服務。</p>
      </div>

      <div class="paragraph bold">
        <p>服务费用明細：</p>
        <p>金額：$40000</p>
        <p>收費方式：分三次支付</p>
        <p>付款時間：簽約啟動後</p>
        <p>能否退款：若申請失敗，扣除20000美元後退還剩餘金額</p>
      </div>

      <div class="paragraph bold">
        <p>協力廠商費用</p>
        <p>1、美國公民及移民部會在申請過程中收取如（I140申請費、NVC轉換身份/I485轉換身份費用、移民簽證費、體檢費、打指膜費、公證費）等，期間會因為政策變化變動，非我方收取費用。</p>
        <p>2、材料翻譯費（支付給協力廠商專業翻譯公司）</p>
        <p>3、商業計畫書（如需要赴美計畫，將由專業的商業計畫書撰寫公司撰寫）</p>
        <p>4、材料補充（支付給相關協力廠商公司）</p>
      </div>
    `
  },
  {
    key: 'CH65422',
    title: '美國移民EB1/EB2全案委託服務函',
    content:
    `
      <div class="pargaph bold">服務內容：</div>

      <div class="pargaph">
        <p class="bold">前期：確定申請方案</p>
        <p>1.核對客戶提供的資訊、材料，通過雙方溝通、網上資源等方式收集有用資料，並篩選符合美國移民局要求的申請資訊</p>
        <p>2.結合行業背景、申請人的個人具體情況，制定申請方向及資料清單</p>
        <p>3.根據個人具體情況，製作申請時間计划（期间需要客户配合计划进行资料准备）</p>
        <p>4.根據美國移民局最新的移民申請綱領和決定，以及您個人的具體情況，選擇適用於你的申請的正確方向</p>
      </div>
      <div class="pargaph">
        <p class="bold">中期：文書撰寫及申請材料整理</p>
        <p class="bold">核心文書（個人陳述、簡歷、推薦信）</p>
        <p>個人陳述</p>
        <p>b）指導您提供能佐證您個人能力與成就的資訊，並撰寫您的個人陳述/赴美聲明</p>
        <p>簡歷</p>
        <p>a）結合您的情況，撰寫個性化簡歷（如需）</p>
        <p>推薦信相關：</p>
        <p>a)指導您如何找適用於您的推薦人</p>
        <p>b)根據您的專業領域，為您撰寫“貼身”推薦信，重點突出您的傑出才能，增強推薦信的說服力</p>
        <p>c)經您審核後，再次對您的推薦信進行修改潤色，並最終定稿</p>
        <p>6.撰寫申請相關條件文書材料(如赴美商業計畫書、工作證明信、專利轉化證明……)</p>
        <p>7.在準備過程中，如遇到申請材料丟失、無法提供等困難，我們將結合實際實際情況為您提供可行的解決方案，以確保您的美國申請可以順利遞交。</p>
        <p>8.跟進申請材料的翻譯及校對（通常用時需1-2個月，具體要看申請資料的數量）</p>
        <p>9.在整合材料完成後，我們的律師會起草一封強有力的申請信，根據您所提供的成就和貢獻，歸納總結證據，列舉事實，涵蓋您所有的證明的各個要點，在關鍵的地方進行有效辯論，充分解釋這些材料為何能夠說明您符合EB1/EB2的標準</p>
        <p>10.填寫所有相關的移民申請表格</p>
        <p>11.整理材料+製作目錄+列印所有材料</p>
      </div>
      <div class="pargaph">
        <p class="bold">後期：申請跟進+轉換身份</p>
        <p>申請跟進</p>
        <p>12.在審核全部的申請材料後，將完整的申請材料提交給移民局</p>
        <p>13.定期查看您的案件申請進展，並與您同步結果。</p>
        <p>14.若移民局發出了補充材料通知，我們將結合補料要求及您的情況制定相應的補料方案、準備好補充文件，並在規定時間內遞交補料。</p>
      </div>
      <div class="pargaph">
        <p>轉換身份</p>
        <p>15.獲批之後，協助你進行NVC面試準備、體檢預約、身份轉換及其他相關服務。</p>
        <p>16.提供入境指導、綠卡郵寄服務。</p>
      </div>
      <div class="pargaph bold">
        <p>服务费用明細：</p>
        <p>金額：$30000</p>
        <p>收費方式：分三次支付</p>
        <p>付款時間：簽約啟動後</p>
        <p>能否退款：若申請失敗，扣除20000美元後退還剩餘金額</p>
      </div>
      <div class="pargaph bold">
        <p>協力廠商費用</p>
        <p>1、美國公民及移民部會在申請過程中收取如（I140申請費、NVC轉換身份/I485轉換身份費用、移民簽證費、體檢費、打指膜費、公證費）等，期間會因為政策變化變動，非我方收取費用。</p>
        <p>2、材料翻譯費（支付給協力廠商專業翻譯公司）</p>
        <p>3、商業計畫書（如需要赴美計畫，將由專業的商業計畫書撰寫公司撰寫）</p>
        <p>4、材料補充（支付給相關協力廠商公司）</p>
      </div>

    `
  },
  {
    key: 'CH34635',
    title: '美國移民資料清單製作服務',
    content:
    `
      <div class="paragraph bold">服務內容：</div>
      <div class="paragraph">
        <p class="bold">資料清單準備</p>
        <p>1.安排頭腦風暴，針對客戶情況梳理好申請條件</p>
        <p>2.結合溝通情況整理好資料清單</p>
        <p>3.提供所需文件的參考範本（比如工作證明信、審稿證明）</p>
        <p>4.針對背景不強的客戶，可針對性提出提升思路——常見的有媒體報道、論文發表、專利、協會list</p>
        <p>5.結合客戶背景提供一些符合要求的協會，客戶自行申請加入</p>
      </div>
      <div class="paragraph bold">
        <p>服务费用明細：</p>
        <p>金額：$1500</p>
        <p>收費方式：一次支付</p>
        <p>付款時間：簽約啟動後</p>
        <p>能否退款：无</p>
      </div>

    `
  },
  {
    key: 'CH59155',
    title: '美國移民申請材料審核服務函',
    content:
    `
      <div class="paragraph">
        <p class="bold">申請材料整理與審核</p>
        <p>1.文書潤色與審核，為您審核美國移民文書、和申請相關的佐證材料的有效性，並提出修改意見</p>
        <p>2.針對您的情況，提出個性化優化方案，讓您更好地展現您的申請優勢</p>
        <p>3.如有需要，可為您提供相應的參考範本（比如工作證明信、審稿證明）</p>
      </div>
      <div class="paragraph bold">
        <p>服务费用明細：</p>
        <p>金額：$2500</p>
        <p>收費方式：一次支付</p>
        <p>付款時間：簽約啟動後</p>
        <p>能否退款：无</p>
      </div>

    `
  },
  {
    key: 'CH27853',
    title: 'I-140 補料服务函',
    content:
    `
      <div class="paragraph">若您收到移民局補料，我們將結合補料要求及您的情況制定相應的補料方案、準備好補充文件，並在規定時間內遞交補料。</div>
      <div class="paragraph bold">
        <p>服务费用明細：</p>
        <p>金額：$1000</p>
        <p>收費方式：一次支付</p>
        <p>付款時間：簽約啟動後</p>
        <p>能否退款：无</p>
      </div>
    `
  },
  {
    key: 'CH59439',
    title: 'NVC+面試服务函',
    content:
    `
      <div class="paragraph">
        <p class="bold">NVC階段</p>
        <p>A）協助繳納移民簽證費</p>
        <p>B) 填寫DS-260表格</p>
        <p>C) 結合申請人情況，指導準備相應的公證書等必要文件</p>
        <p>D) J1 豁免（不含）</p>
        <p class="bold">廣領館面試（若等待較久或申請人個人情況有變更， DS-260可能要再修改）</p>
        <p>E) 指導體檢預約</p>
        <p>F) 面試材料準備及面試輔導（1次，線上遠程）</p>
        <p>G) 面試延期</p>
        <p>H) 補件</p>
        <p>I) 進展跟進</p>
      </div>
      <div class="paragraph bold">
        <p>服务费用明細：</p>
        <p>金額：$3500</p>
        <p>收費方式：一次支付</p>
        <p>付款時間：簽約啟動後</p>
        <p>能否退款：无</p>
      </div>

    `
  },
  {
    key: 'CH77126',
    title: 'I-485服务函',
    content:
    `
      <div class="paragraph">
        <p>A）協助繳納申請費</p>
        <p>B) 填寫I-485表格（其他表格：若申請工卡——I 765； 申請回美證——I 131）</p>
        <p>C) 結合申請人情況，指導準備相應的公證書等必要文件</p>
        <p>D) 指導體檢預約</p>
        <p>E) 面試輔導（基本不要求面試，但也有可能安排面試）</p>
        <p>F) 補件</p>
        <p>G) 進展跟進</p>
      </div>
      <div class="paragraph">
        <p>1.其他</p>
        <p>A)NVC與485 互換，有的申請人本來原來選擇NVC路徑，後來想轉成境內轉換身份，或相反）</p>
        <p>B)綠卡郵寄服務</p>
      </div>
      <div class="paragraph bold">
        <p>服务费用明細：</p>
        <p>金額：$3000</p>
        <p>收費方式：一次支付</p>
        <p>付款時間：簽約啟動後</p>
        <p>能否退款：无</p>
      </div>
    `
  }
]