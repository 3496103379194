<template>
  <div class="footer">
    <p> © 2024 kitointl.com All Rights Reserved.  |  聯絡 fd@7tao.net</p>
  </div>
</template>
<script setup>


</script>
<style lang="scss" scoped>
.footer{
  display: flex;
  justify-content: center;
  background: #F6F7FB;
  color: #8f9093;
  height: 50px;
  align-items: center;
}

</style>
