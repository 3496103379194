import { createI18n } from 'vue-i18n'
import en from './en'
import zh from './zh'
import zhHant from './zh-hant'

// 组合语言包对象
const messages = {
  en,
  zhHant,
  zh
}

const i18n = createI18n({
  // something vue-i18n options here ...
  legacy: false,  // 设置为 false，启用 composition API 模式
  messages,
  locale: 'zhHant'
})

export default i18n