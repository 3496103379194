<template>
  <router-view/>
</template>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  font-size: 14px;
  color: #000;
  max-width: 100%;
  min-width: 1300px;
}
#app::-webkit-scrollbar {
  width: 0;
}

body,html{
  height:100%;
  margin:0
}


html,body{
  margin:0
}

p{
  margin:0
}
img{
  display: block;
}
</style>
