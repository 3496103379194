import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import pageContainer from './components/pageContainer.vue'
import i18n from "@/language/index"

const app = createApp(App)

app.config.globalProperties.$COS = 'https://qtiao-web-1258997378.cos.accelerate.myqcloud.com/kitointl/PC/'

app.component('pageContainer', pageContainer)

app.use(i18n).use(store).use(router).mount('#app')
