
export const nonimmigrant = [
  {
    title:'H-1B 工作簽證说明',
    children:[
      {
        title:'H-1B 工作簽證说明',
        content:`
          <div class="paragraph">
            H-1B簽證是一種非移民簽證，它允許美國雇主臨時聘用從事“專業職位”的外籍專業技術人員。為了申請H-1B簽證，雇主和雇員都必須滿足移民局的相關具體要求，並且雇主需要為H-1B受益人預先向勞工部遞交“勞工情況申請”（LCA）。
          </div>
          <div class="paragraph">
            我們在下面將具體闡述H-1B簽證的要求。
          </div>
          <div class="paragraph">
            工作職能
          </div>
          <div class="paragraph">
            首先，美國雇主必須證明H-1B受益人擔任的職位是“專業職位”， “專業職位”是指滿足以下要求的職位（任一即可）：
          </div>
          <div class="paragraph">
            該職位通常至少需要學士學位或更高學位或相當的學歷；
          </div>
          <div class="paragraph">
            -本條特殊情況：對於來美臨時工作的時裝模特，則沒有對應的學位要求，因為模特界本身對學歷並無硬性要求，所以沒有對應學位的模特也可以申請H-1B簽證。
          </div>
          <div class="paragraph">
            一般來說，該職位對學士學位沒有明確要求，但實際操作中，這個工作的複雜程度使得只有擁有某種學位的人才能勝任；
          </div>
          <div class="paragraph">
            由於該職位的專業性和複雜性，只能由擁有學士學位或更高學位的人來擔任，因為他們有專業化的知識；
          </div>
          <div class="paragraph">
            <p>該職位通常被認為是“專業性”職位；</p>
            <p>-“專業性”職位包括但不限於：建築師、工程師、教授、老師、研究人員、醫療專家、營養學家、醫師、護士、電腦程式員、會計師、律師、社會工作者、經濟學家、圖書管理員。</p>
          </div>
          <div class="paragraph">
            雇員教育背景
          </div>
          <div class="paragraph">
            除了工作職能外，對外籍雇員的教育背景也有相關要求。提供支持的美國雇主必須證明受益人的教育背景滿足從事專業職位的要求。一般來說，外籍雇員須擁有美國正式認證的大學院校頒發的四年學制的學士學位或更高學位， 該學位證明是擔任專業職位的必要先決條件。
          </div>
          <div class="paragraph">
            如果外籍雇員在美國境外完成了學業，並且她/他的學位是由美國以外的教育機構頒發，那麼該學位必須由美國評估機構根據其個人情況來進行評估，以此來決定該學位是否和美國頒發的學位相當。
          </div>
          <div class="paragraph">
            如果外籍員工擁有相應的學位，那麼對工作經驗的要求就可以相應地豁免。如果外籍員工不能達到H-1B對學位學歷的要求，那麼他/她就必須擁有相應的工作或培訓經驗。在一些情況下，工作或培訓經驗可以彌補學歷的不足，三年的專業工作經驗可以相當於一年的大學教育。
          </div>
        `
      },
      {
        title:'申请流程',
        content:`
          <div class="paragraph">
            <p class="bold">申請流程：</p>
            <p>當外籍人士獲得並接受美國雇主提供的需要專業性知識的職位後，就可以開始進行H-1B簽證的申請了。我們將申請的過程和步驟總結如下。</p>
            <p>時間安排</p>
          </div>
          <div class="paragraph">
            H-1B簽證每年的名額是法定的，由美國國會設定。每個財年普通H-1B工作簽證只有65,000個配額 (Regular Cap), 其中還包括6,800個給新加坡和智利公民的配額。另外, 在美國獲得碩士或者碩士以上的受益人還有20,000個配額 (Master’s Exemption) 。如果遞交的人數超過這85,000個申請的話, 那麼移民局抽籤機制就啟動了。
          </div>
          <div class="paragraph">
            第一輪抽籤，所有學歷受益人統一抽取65,000個名額。第一輪落選的研究生及研究生以上學歷的受益人再進行第二輪 20,000個名額的抽籤。需要注意的一個概念，我們通常談起初次遞交H-1B的申請就會調侃受益人求錦鯉, 求中獎，大家跪求的是抽中遞交申請材料給移民局的遞件資格，抽籤並不涉及案件的審理，中簽也不代表你獲得了H-1B批准。
          </div>
        `
      },
      {
        title:'申请步骤',
        content:`
          <div class="paragraph">
            H-1B的申請過程和步驟如下所述。請注意，H-1B簽證的申請人是美國雇主，而受益人是外籍員工。
          </div>
          <div class="paragraph">
            美國雇主為外籍員工提供了一個需要專業知識的崗位。在外籍員工接受了這份工作後，美國雇主同意為其申請H-1B簽證。
          </div>
          <div class="paragraph">
            雇主聘請移民律師來協助其申請，從而建立了委託代理關係。
          </div>
          <div class="paragraph">
            律師根據H-1B的申請要求收集所有的檔和資訊。
          </div>
          <div class="paragraph">
            -這些材料包括：詳細的職位介紹，準確的預期雇傭時間，雇主相關資訊，以及外籍受益人（雇員）的資格和工作經驗等資訊。
          </div>
          <div class="paragraph">
            律師查詢受益人的職位在該地區的現行工資標準（Prevailing Wage）。
          </div>
          <div class="paragraph">
            -為此，律師通常會先通過搜索勞工部的線上資料庫來確定現行工資標準。
          </div>
          <div class="paragraph">
            -如果通過以上方法得出的現行工資標準高於雇主為受益人提供的工資標準，那麼律師將通過該職位所在的州的勞工部薪資與工時處（Wageand Hour Division）的數據來確定現行工資標準。
          </div>
          <div class="paragraph">
            -如果州勞工部薪資與工時處（Wage and Hour Division）的數據還是高於雇主提供的工資標準，那麼律師需要通過別的工資數據來計算現行工資標準。
          </div>
          <div class="paragraph">
            -雇主申請人的代理律師向勞工部遞交《勞工情況申請表》（LCA）。
          </div>
          <div class="paragraph">
            -這份申請將約束雇主向外籍員工支付不低於現行工資標準的薪金，為H-1B員工提供和其他員工平等的待遇，並且確保雇用該外籍員工不會對其他員工的工作產生負面影響，包括確保公司內部沒有出現有關外籍員工即將開始工作的職位的罷工或者停工。
          </div>
          <div class="paragraph">
            -LCA的評估通常需要一周的時間。
          </div>
          <div class="paragraph">
            獲得勞工部批准的LCA後，代理律師可向移民局遞交H-1B申請。
          </div>
          <div class="paragraph">
            -申請遞交的所有資料包括：申請要求的所有檔、表格（包括I-129表）以及申請費。
          </div>
          <div class="paragraph">
            一般來說，當申請數量超過名額上限時，移民局會用電腦抽籤來選定哪些申請占到名額，處理中簽H-1B申請的時間約為2-4個月。
          </div>
        `
      },
      {
        title:'H-1B常規材料清單',
        content:`
          <div class="paragraph">
            <p>● 雇主相關資料：</p>
            <p>公司名稱、地址、電話和傳真號碼</p>
            <p>工作職位名稱，職責敘述，工資及最低要求資訊</p>
            <p>代表公司簽字的人名和頭銜</p>
            <p>公司的聯邦稅號</p>
            <p>公司宣傳冊</p>
            <p>公司信頭紙（數張）</p>
          </div>
          <div class="paragraph">
            <p>● 雇員相關資料：</p>
            <p>雇主的工作承諾信和支持申請的資訊</p>
            <p>個人簡歷</p>
            <p>畢業證書和學位證書</p>
            <p>大學成績單</p>
            <p>護照</p>
            <p>執照，如果要求的話</p>
            <p>此前在美的合法身份證明（如有）</p>
            <p>如果人在美國不要求但最好有的材料：</p>
            <p>I-94出入境記錄卡的影本</p>
            <p>授權OPT的I-20影本</p>
            <p>以前的H-1B批准通知影本（如有）</p>
            <p>最近的工資單影本（如有）</p>
            <p>美國社安號（如有）</p>
            <p>以前J-1批准通知影本（如有）</p>
            <p>親屬相關資料：</p>
            <p>護照</p>
            <p>I-94出入境記錄卡的影本（如有）</p>
            <p>美國合法身份證明</p>
            <p>結婚證（配偶）</p>
            <p>出生證明（子女）</p>
          </div>
        `
      }
    ]
  },
  {
    title:'L-1工作簽證介绍',
    children:[
      {
        title:'L-1工作簽證介绍',
        content:`
          <div class="paragraph">
            L-1簽證是非移民工作簽證，它適用於在美國和受益人所屬國都有商業機構的跨國公司派遣內部人員到美國分公司進行短期工作。在L-1簽證中，跨國公司是L-1簽證的申請人，外籍員工是L-1簽證的受益人。L-1簽證的受益人在提交L1簽證申請之前的三年內，必須在其作為申請人的跨國公司的海外機構（非美國境內公司）至少連續工作一年。該跨國公司在海外的機構必須和在美國的機構屬於母子公司關係，總分公司關係或是附屬關聯關係。也就是說，提交L-1簽證申請的美國公司必須是其境外公司的母公司，子公司，或關聯公司。除了盈利的跨國公司外，L-1簽證還可適用於跨國的非盈利的組織，跨國宗教組織或跨國慈善機構。
          </div>
          <div class="paragraph">
            本文將介紹L-1簽證，並就其申請要求進行簡單分析。
          </div>
          <div class="paragraph">
            L-1簽證適用對象
          </div>
          <div class="paragraph">
            無論是大公司或小公司，都可通過申請L-1簽證將員工外派至美國工作。
          </div>
          <div class="paragraph">
            <p>申請L-1簽證可使已有國際業務的大規模公司受益。當一家跨國公司向一個新國家拓展的時候，通常需要派遣有專業知識的員工前往異國的新成立的辦公室工作。大公司通常都會有國際輪換政策，確保管理層內所有的核心員工都有平等的職業發展機會。一旦該跨國公司的境外公司有職位空缺，那麼這些核心員工就可以通過公司內部職位輪換，得到跨國工作的機會。除此之外，申請L-1簽證對公司制度也大有益處。高層員工和高管之間的意見交流能夠提高公司的競爭力，促進交流創新，這對公司的聲譽和發展都至關重要。核心員工的定期輪換也能提升公司內部服務和流程的一致性，從而促進其國際水準的提高。</p>
            <p>總而言之，L-1簽證是為促進不同國家間公司內部派遣的需要而設計的，主要分為兩類：L-1A和L-1B。</p>
          </div>
          <div class="paragraph">
            L-1A：內部調遣——跨國公司經理和高管人員
          </div>
          <div class="paragraph">
            L-1A簽證是為跨國公司將公司內部的高級經理及高管人員派遣到美國的分公司，子公司，母公司或者關聯公司工作設計的。 L-1A簽證受益人必須在公司為其提交申請的前3年內至少連續為該跨國公司在美國境外的公司工作一年。另外，L-1A允許尚未建立美國辦公室的跨國公司將高管人員派到美國進行美國公司辦公室的籌建。對於新公司，首次L-1A簽證的有效期通常為1年，對於現存公司（成立並運營超過一年時間），首次L-1A簽證的有效期通常為3年，有效期到達後如需要申請延期，單次延期的時間不得超過兩年。L-1A簽證的最長有效期限是7年。
          </div>
          <div class="paragraph">
            L-1B：內部調派——跨國公司專業技術人員
          </div>
          <div class="paragraph">
            L-1B簽證是為跨國公司將具有專業知識的人員派遣到美國工作而設計的。比方說，如果某公司內部有一名員工非常瞭解公司產品，並掌握了產品的專有知識，為了向美國的新員工傳授關於產品的專有知識，公司打算將該員工派遣到美國，那麼就可以為其申請L-1B簽證。同L-1A簽證一樣，L-1B簽證允許尚未建立美國辦公室的跨國公司將專業技術人才送至美國，幫助美國公司辦公室的籌建。通常L-1B的首次有效期為3年，到期後可申請延期一次，延期的時間不超過兩年。L-1B簽證的最長效期限是5年。
          </div>
          <div class="paragraph">
            無論是L-1A還是L-1B， 在L-1簽證申請中，外籍員工即將前往工作的美國公司和其之前工作的境外公司必須有合格的相聯公司關係，例如母子公司關係等。
          </div>
          <div class="paragraph">
            L-1跨國公司集體調職簽證（L-1 Blanket Petition）
          </div>
          <div class="paragraph">
            對於那些經常使用L-1簽證進行員工外派的大型跨國公司，移民局為其建立了一種特殊的簽證申請程式，即L-1集體調職簽證申請程式“blanket petition”。在這種申請程式下，滿足移民局條件的大型跨國公司可以通過一次申請將一定數量的經理級高管和擁有專業知識的技術人員派遣到美國的公司工作，這樣更加快捷高效，節省時間。
          </div>
        `
      },
      {
        title:'L-1A 的申請條件',
        content:`
          <div class="paragraph">
            L-1簽證是短期非移民工作簽證，它適用於跨國公司將合乎條件的外籍員工派遣至美國的母公司，子公司，分公司或關聯公司進行短期工作。
          </div>
          <div class="paragraph">
            L-1簽證有兩種類型：L-1A簽證和L-1B簽證。
          </div>
          <div class="paragraph">
            L-1A簽證是用於派遣跨國公司高級經理和高管人員的工作簽證；L-1B簽證是用於派遣跨國公司具有專業知識（specialized knowledge）的技術人員的工作簽證。不同的類型在申請時有不同的申請要求。
          </div>
          <div class="paragraph">
            在下面， 我們將分別闡述對L-1簽證申請人的跨國公司的要求，以及L-1A，L-1B簽證對外派雇員的要求。
          </div>
          <div class="paragraph">
            <p>對於作為L-1簽證申請人的跨國公司的一般要求</p>
            <p>在L-1簽證申請過程中，申請人是跨國公司，它向移民局提出L-1申請，為外派雇員申請L-1簽證，因此外派雇員是L-1申請的受益人。無論是申請L-1A還是L-1B，作為L-1簽證申請人的跨國公司須滿足如下條件：</p>
          </div>
          <div class="paragraph">
            <p>合格的公司關係（qualifying relationship）</p>
            <p>-“合格的公司關係”是指，在L-1申請中，作為申請人的跨國公司，必須至少擁有兩個機構（或公司），一個是在美國境內的機構（或公司），一個是在美國境外的機構（或公司）。這兩個境內外的機構（或公司）之間必須存在符合移民法規定的合乎條件的公司關係，這樣的關係包括：母子公司關係、總分公司關係、關聯公司關係。如果滿足上述關係，那麼這樣的公司就可以被稱作“合格的商業實體（qualifying entities）”或“合格的公司（qualifying organizations）”。</p>
          </div>
          <div class="paragraph">
            -請注意，L-1簽證並不限制商業實體的組織形式，它可以是企業、非盈利組織、宗教或慈善機構。
          </div>
          <div class="paragraph">
            <p>正常運營（doing business）</p>
            <p>- “正常運營”是指，作為L-1申請人的跨國公司，必須在L-1受益人的簽證有效期內，直接地或通過與其擁有合格關係的公司（qualifying organization），在美國境內外正常開展業務或即將開展業務。</p>
          </div>
          <div class="paragraph">
            -上述業務只需切實可行（must be viable），不要求一定涉及國際貿易。
          </div>
          <div class="paragraph">
            -這裏的“正常運營”是指正常的、有系統的、持續的提供商品和/或服務。僅在美國境內或境外設有辦公室或聘請代理人並不能滿足申請要求。
          </div>
          <div class="paragraph">
            有些跨國公司很容易滿足上述L-1申請要求，不需要提供大量的證明材料，特別是那些眾所周知的大型公司。但是對於規模較小或是初創公司來說，提供哪些材料才能讓人信服是比較有挑戰的。下麵我們針對計畫在美成立新公司的情況再探討探討。
          </div>
          <div class="paragraph">
            如果境外跨國公司計畫派L-1受益人去美國籌建新公司，那麼還須滿足以下要求：
          </div>
          <div class="paragraph">
            必須證明新公司已有足夠的辦公場所（sufficient physical premises）;
          </div>
          <div class="paragraph">
            L-1外派員工必須在提交L-1申請的前三年內，至少連續一年在境外跨國公司擔任高級經理或高級管理職位；
          </div>
          <div class="paragraph">
            在L-1簽證批准後的一年內，新公司的業務將會發展到有能力為L-1外派員工提供高級經理或高級管理的職務。
          </div>
          <div class="paragraph">
            在這種情況下，我們建議這種類型的公司向經驗豐富的移民律師尋求幫助。
          </div>
          <div class="paragraph">
            L-1A對外派雇員（高級經理及高管人員）的要求
          </div>
          <div class="paragraph">
            作為L-1A簽證受益人的高級經理及高管人員需要滿足如下條件：
          </div>
          <div class="paragraph">
            在提交L-1A申請的前3年內，受益人必須至少在與美國公司擁有“合格的關係”的境外公司至少連續工作一年。在美國境內為該境外公司工作的時間不計算在境外工作的一年時間之內；
          </div>
          <div class="paragraph">
            該受益人在美國境外公司必須擔任高級經理或高級管理職位，也就是通常所說的“合格的職位（qualifying position）”。
          </div>
          <div class="paragraph">
            <p>該受益人在美國境內公司也必須同樣擔任高級經理或高級管理職位。</p>
            <p>-根據聯邦法，“高級管理決策性職責（executive capacity）”是指某員工擁有廣泛參與決策的能力，並無需過多的監督。</p>
          </div>
          <div class="paragraph">
            -“高級經理管理性職責（managerial capability）”是指某員工對專業人員擁有監督和控制的能力，可以對公司內部的機構、部門、分支機構、公司的職能或公司的組成部分進行管理。該員工還可對公司內部高層的一項重要職能進行管理，但對其他人沒有直接監督權。
          </div>
          <div class="paragraph">
            該受益人的教育水準和工作經驗能夠與其職位相符。
          </div>
          <div class="paragraph">
            在簽證到期之前，該受益人必須將如期離開美國。
          </div>
        `
      },
      {
        title:'L-1B 的申請條件',
        content:`
          <div class="paragraph">
            L-1B對外派雇員（專業技術人員）的要求
          </div>
          <div class="paragraph">
            不同於L-1A受益人，L-1B簽證並未要求受益人必須以高級經理或高級管理人員身份為公司工作。然而在申請時，L-1B要求受益人擁有“專業知識”。根據美國移民局的備忘錄，L-1B受益人如果擁有“特殊知識（special knowledge）”或“高級知識（advanced knowledge）”，或兩者兼備，那麼就可以被視為擁有“專業知識（specialized knowledge）”。移民局關於上述兩類“知識”定義如下：
          </div>
          <div class="paragraph">
            特殊知識（special knowledge）：是指個人對提交簽證申請的跨國公司的產品、服務、研究、設備、技術、管理或其他利益所需技能，以及在國際市場的運作方式的特殊認知，並且這種認知完全異於特定領域內普遍認知；
          </div>
          <div class="paragraph">
            高級知識（advanced knowledge）：是指個人對提交簽證申請的跨國公司的具體程式及流程擁有的高度瞭解或專長。這種瞭解或專長在相關領域並不常見，並且其先進程度在對公司的發展性、複雜性和理解上遠超公司內部一般員工。
          </div>
          <div class="paragraph">
            提交申請的美國公司應參考以下問題，以確認即將從境外公司外派至美國的專業知識技術人員是否擁有上述的“專業知識”：
          </div>
          <div class="paragraph">
            該外派員工是否瞭解掌握境外運營的情況，並且這種瞭解能夠為提交申請的跨國公司的在美運營帶來重要價值？
          </div>
          <div class="paragraph">
            該外派員工在境外公司的工作是否很大幅度地提高了境外公司的生產力、競爭力、公司形象以及財務地位？
          </div>
          <div class="paragraph">
            該外派員工所掌握的專業知識是否只能通過在該跨國公司的美國境外機構（或公司）內工作才能夠獲得？
          </div>
          <div class="paragraph">
            是否該外派員工對於產品或流程所掌握的知識由於不便，或因沒有巨大的經濟投入而無法輕易傳授給另外一個人？
          </div>
          <div class="paragraph">
            該外派員工是否對產品或流程的知識非常成熟，複雜，或具有高技術性？
          </div>
          <div class="paragraph">
            該外派員工所掌握的知識是否顯而易見地有利於提高雇主公司的市場競爭力？
          </div>
          <div class="paragraph">
            關於上述任意一個問題或多個問題，雇主公司如果有肯定的答案，那就表明該外派員工擁有了L-1B申請要求的“專業知識”。根據移民局的不完全列表，為了證明外派員工具有“專業知識”，雇主公司需提交以下檔材料：
          </div>
          <div class="paragraph">
            外派員工的教育、培訓或工作經歷的檔證明，用以確認在此教育、培訓或工作期間其不斷使用或發展了“專業知識”；
          </div>
          <div class="paragraph">
            外派員工在美國公司工作後會對該公司的運營產生影響的證明；
          </div>
          <div class="paragraph">
            外派員工獨具能對增強美方公司海外運營能力有所貢獻的專業知識的證明；
          </div>
          <div class="paragraph">
            合同、工作說明書或其他證明檔表明該外派員工擁有的“專業知識”對提升跨國公司的市場競爭力大有益處；
          </div>
          <div class="paragraph">
            相關信函或報告，用以證明該外派員工在境外公司的工作很大幅度地提高了境外公司的生產力、競爭力、公司形象以及財務地位；
          </div>
          <div class="paragraph">
            人事或內部培訓記錄，用以證明該外派員工所掌握的專業知識只能通過在該跨國雇主公司的工作或培訓才能夠獲得；
          </div>
          <div class="paragraph">
            內部培訓的課程手冊或培訓手冊、財務檔或其他材料，用以證明如果沒有顯著的經濟投入，該外派員工對於產品或流程所掌握的知識就無法輕易傳授給另外一個人；
          </div>
          <div class="paragraph">
            申請公司由於該外派員工的工作而獲得的專利、商標、許可證或合同；
          </div>
          <div class="paragraph">
            相關材料證明該外派員工對產品或流程掌握的知識非常成熟，複雜，或具有高度技術性（不要求該外派員工是該公司內唯一掌握該知識的人）；
          </div>
          <div class="paragraph">
            薪資檔、聯邦或州工資報告，各種補貼證明材料、簡歷、公司的組織結構圖或其他類似檔，表明該外派員工在申請公司的職位和薪資水準；
          </div>
          <div class="paragraph">
            除了準備上述必要的證明檔外，提交L-1B申請的雇主公司還須保證滿足以下附加條件：
          </div>
          <div class="paragraph">
            在提交L-1申請的前3年內，該外派員工必須至少在與美國公司擁有“合格的關係”的境外公司至少連續工作一年。在美國境內為該境外公司工作的時間不計算在境外工作的一年時間之內；
          </div>
          <div class="paragraph">
            該外派員工赴美後必須在與美國境外公司擁有“合格的關係”的美國公司內工作，並憑藉其專業知識為公司提供服務；
          </div>
          <div class="paragraph">
            簽證到期之時，該外派員工將會如期離開美國；
          </div>
        `
      },
      {
        title:'L-1 的申請流程與材料',
        content:`
          <div class="paragraph">
            <p>L-1簽證流程</p>
            <p>L-1簽證申請流程通常遵循以下順序：I-129表格→DS-160表格→面試。該過程是雇主和工人之間的合作，因為雙方都需要在不同的階段參與。因此，您的L-1簽證程式必須按以下順序完成：</p>
          </div>
          <div class="paragraph">
            <p  class="bold">第 1 步：I-129，非移民工人申請</p>
            <p>就像每個基於就業的簽證類別一樣，您的L-1簽證申請流程必須由您的雇主贊助。您的雇主將代表您提交I-129。申請必須在您的開始日期前至少 45 天提交，並且在您的工作開始前不超過 6 個月。</p>
            <p>以下文件必須與填妥的I-129一起提供：</p>
          </div>
          <div class="paragraph">
            <p>如果您已經在美國，則證明您保持合法身份的證據</p>
            <p>證明您曾在該組織工作並在其駐外辦事處擔任執行或管理職務至少一年</p>
            <p>有關您擬議的工作職責和資格的完整詳細資訊</p>
            <p>證明您將在擬議的工作中擔任高管或管理職位的證據</p>
            <p>I-907 表格（如果您使用加急處理）</p>
            <p>您的雇主還必須證明有能力支付您的工資</p>
            <p>如果 I-129 申請獲得批准，USCIS 將向您的雇主發送 I-797 行動通知。您將使用該表格作為申請國外簽證的檔的一部分。</p>
          </div>
          <div class="paragraph">
            <p class="bold">第 2 步：DS-160 簽證申請</p>
            <p>在您的 I-129 獲得批准後，如果您在美國境外，您將使用 DS-160 繼續申請簽證，該簽證將以電子方式提交並提交給 DOS 網站。領事官員將使用您在表格上提供的資訊和麵試答案來處理您的簽證申請，以決定您是否有資格獲得L-1非移民簽證。</p>
          </div>
          <div class="paragraph">
            完成 DS-160 後，您必須列印並保留申請表確認頁，稍後您將在面試時隨身攜帶。您還需要安排簽證面談預約。
          </div>
          <div class="paragraph">
            此外，作為 DS-160 申請要求的一部分，您需要加載您的照片。照片的尺寸、顏色、格式和大小必須符合國務院的這些照片要求，這一點非常重要。您還需要一本有效護照，該護照在面試日期後至少六個月內有效
          </div>
          <div class="paragraph">
            <p class="bold">第 3 步：面試</p>
            <p>您必須到美國駐本國領事館或大使館參加簽證面試。該過程在不同的領事館和大使館之間差異很大，因此您需要向您所在國家/地區的領事館查詢以獲取更多資訊。作為申請過程的一部分，還將進行無墨水數字指紋掃描。</p>
          </div>
          <div class="paragraph">
            近年來，由於美國公民及移民服務局的不同改革，美國公民及移民服務局的非移民簽證處理時間有所增加。通常，I-129 最多需要六個月的時間來處理。它可能會比這更長，具體取決於處理您的申請的移民或領事辦公室的案件量。
          </div>
          <div class="paragraph">
            另一個可能延長L-1簽證申請時間的因素是您提交的申請中是否存在錯誤或遺漏。提交申請後，您可能會收到美國公民及移民服務局的 RFE。如果您未能按要求提供所有初始證據，您很可能會遇到更長的延遲甚至拒絕。這就是為什麼從一開始就提供所有必要的檔非常重要的原因。
          </div>
          <div class="paragraph">
            <p>L-1 加急處理</p>
            <p>如果您不能等待六個月，您可以使用稱為加急處理的服務來加快您的L-1簽證申請流程。為此，您需要提交 I-907，高級服務請求。如果獲得批准，該請求將保證您在 15 個工作日內完成申請。</p>
          </div>
        `
      },
      {
        title:'L-1 的常见问题',
        content:`
          <div class="paragraph">
            <p>L-1持有人一定要在美進行全職工作嗎？</p>
            <p>申請L-1簽證不需要受益人所有時間都在美國完成全職工作。 儘管L-1簽證持有人必須以全職身份為公司工作，但她/他不一定必須在美國進行全職工作。受益人可以對美國的工作和其母國的工作進行分配完成。因此，雖然L-1簽證持有者主要是受聘於美國境外公司，但其仍可獲得L-1簽證赴美進行短期工作。如果外籍員工赴美的唯一目的是與官員進行商務會談、參與會議或是參加培訓，那麼其應該考慮申請B-1商務簽證，因為這些商務活動並不符合L-1簽證要求的“需要花費定期且系統的、相當長的時間來完成”。</p>
          </div>
          <div class="paragraph">
            <p>L-1簽證持有人的配偶或子女可以一起赴美嗎？</p>
            <p>如果您已經獲得了L-1簽證並即將赴美工作，但又不想離開您的配偶和子女，那麼您可以讓您的配偶和子女申請L-2簽證與您一同赴美生活。美國移民法規定，L-1簽證持有者的配偶和21歲以下的未婚子女可以作為L-1持有者的家屬申請L-2簽證共同赴美。L-2簽證持有者可以合法進入並居留美國，其有效期和L-1簽證的有效期一致。另外，L-2簽證持有者可以向移民局申請工作許可，得到批准後，可以在美國合法工作。</p>
          </div>
          <div class="paragraph">
            <p>L-1持有人能轉永居嗎？</p>
            <p>與其他非移民簽證相比，L-1簽證的優勢之一是允許受益人擁有“雙重傾向（dual intent）”。換句話說，L-1簽證持有者可以有移民傾向，所以在領事館申請簽證的過程中，簽證官不會以申請人具有移民傾向而拒絕其L-1簽證申請，同時L-1持有人可以在美國國內安全轉換到綠卡身份而不會影響其L-1身份。另一個優勢是，如果L-1A持有人打算通過申請EB-1C得到美國綠卡，由於EB-1一般沒有排期，當申請人的EB-1C申請獲得批准後，申請人就可以很快進行身份調整從而快速獲得綠卡，不需要為漫長的綠卡配額排期進行等待。</p>
          </div>
          <div class="paragraph">
            <p>L-1續簽難嗎？</p>
            <p>當簽證最長有效期到期時，外籍人士需要離開美國並在美國境外居住至少一年，在此期間其須為美國公司的境外關聯公司工作，之後方可重新申請L簽證。</p>
          </div>
        `
      },
    ]
  },
  {
    title:'J-1訪問簽證',
    content:`
      <div class="paragraph">
        J-1簽證是一種非移民簽證（Nonimmigrant Visa），是簽發給來美國參加美國國務院批准的「交流訪問者計畫」（Exchange-Visitor Programs），進行短期學習、學術訪問或從事研究工作的各類外籍交流訪問學者，因此它又稱J1訪問學者簽證。J1簽證提供了美國和其他國之間的教育和文化交流的機會，增進瞭解，促進國際合作併發展友好關係。
      </div>
      <div class="paragraph">
        1簽證有多種類別，各類J1簽證持有人可以在美國停留的時間也不一樣。而J1簽證最大的好處就是包含了多種類別，涵蓋了留學、培訓、工作等多個方面。除此之外，J-1簽證還有一個好處就是申請起來比較容易，因為部分J1簽證會受到回國兩年的限制，所以一般J1申請人較少被懷疑具有移民傾向，而且J1簽證沒有名額的限制。
      </div>
      <div class="paragraph">
        當J-1簽證交換訪問者完成他/她的交流訪問專案後，他/她的寬限期（Grace Period）開始。J-1簽證的Grace Period 是30天的。在這30天內，交換訪問者可以留在美國，做離開的準備，但是在這個寬限期內，是不允許工作，也不可以離開美國，在寬限期內再重新進入美國。
      </div>
      <div class="paragraph">
        根據交流訪問計畫的不同，J-1簽證主要包括以下類別：
      </div>
      <div class="paragraph">
        J-1學生簽證
      </div>
      <div class="paragraph">
        分為J1高中學生和J1學院/大學學生。 J1高中學生必須符合一定的年齡要求，且必須參加全日制學習。值得注意的是，J1高中學生不能參加12年級的學習，也不能獲得美國高中的畢業文憑。
      </div>
      <div class="paragraph">
        J1學院/大學學生沒有嚴格的年齡限制，但也必須參加全日制學習，包括有學位的學習（degree program）、無學位的學習（non-degree program）或學術性實習（academic training）。
      </div>
      <div class="paragraph">
        J-1短期訪問學者簽證
      </div>
      <div class="paragraph">
        此類簽證適用於赴美從事以下活動不超過一定期限的短期訪問學者：
      </div>
      <div class="paragraph">
        <p>授課</p>
        <p>考察</p>
        <p>交流意見</p>
        <p>接受培訓</p>
        <p>在研究機構、博物館、圖書館、高等教育機構或類似的機構展示特殊技能</p>
        <p>J-1受訓人員（trainees）簽證</p>
      </div>
      <div class="paragraph">
        <p class="bold">申請材料一覽</p>
        <p class="bold">美方公司須準備的文件</p>
        <p>公司業務介紹</p>
        <p>公司章程</p>
        <p>公司聯邦報稅號碼EIN申請表（Form SS-4）</p>
        <p>股東持股證明</p>
        <p>公司辦公地租約</p>
        <p>初始投資的銀行對帳單或轉賬憑證</p>
        <p>審計過的財務報表（資產負債表、損益表或現金流量表等）</p>
        <p>公司報稅表（Form 1120），如有</p>
        <p>公司季度報告表（Form 941），如有</p>
        <p>公司的商業交易檔（例如：商業合同、發票、提貨單、信用證等）</p>
        <p>帶有公司標誌、名稱和地址的公司信頭紙（數張）</p>
        <p>反映公司辦公室內部和外部的照片</p>
      </div>
      <div class="paragraph">
        <p class="bold">境外公司須準備的文件</p>
        <p>公司章程</p>
        <p>公司營業執照</p>
        <p>過去三年的公司報稅表</p>
        <p>審計過的財務報表（資產負債表、損益表及現金流量表等）</p>
        <p>公司組織結構表，需要包含員工總人數及外派員工的工作職位資訊</p>
        <p>公司手冊或產品介紹</p>
        <p>公司的商業交易文件（例如：商業合同、發票、提貨單、信用證等）</p>
        <p>銀行對帳單或交易記錄</p>
        <p>帶有公司標誌、名稱和地址的信頭紙（數張）</p>
        <p>反映公司辦公室、工廠或辦公樓內部和外部的照片（如果公司宣傳資料中已經包括了這些內容，則不必單獨提供）</p>
      </div>
      <div class="paragraph">
        <p class="bold">外派員工須準備的文件</p>
        <p>簡歷</p>
        <p>學歷證明</p>
        <p>由該外派員工所任職的境外公司出具的在職證明</p>
        <p>董事會決議或任命檔，闡明該外派員工將從他國調派至美國</p>
        <p>其他可以證明該外派員工具有符合高級經理、高級管理職位的能力，或具有專業知識的文件</p>
      </div>
    `
  },
  {
    title:'K類 未婚夫（妻）簽證',
    content:`
      <div class="paragraph">
        <p>K-1簽證</p>
        <p>在有些文化背景中，在自己民族或地區以外的地方結婚是不被接受的。所以，有些情侶更傾向於在美國結婚而不是在外國未婚妻（未婚夫）自己所在的國家結婚。對於這些人，K-1簽證是一個非常不錯，或者應該說最好的選擇。</p>
        <p>K-1簽證是一款臨時性簽證，成功申請到K-1簽證入境後，簽證持有者在入境後90天內需要註冊結婚，完婚之後受益人才能在美國進行身份調整，作為美國公民的配偶以K-1簽證申請綠卡。</p>
        <p>K-1簽證一般有效期為6個月，但只有一次入境的機會。如果在90天內不能完婚，這一臨時簽證即失效。</p>
        <p>K-1簽證的目的是讓居住在美國以外的外國未婚夫/妻來到美國與美籍未婚妻/夫完婚。這種簽證被歸入非移民簽證類。非移民簽證的批准必須基於一個前提，即申請人赴美是臨時性的，並且其到美國的目的是完成一個特定的使命。 具體到K簽證，就是和相應的美國公民完婚。如果K-1簽證的持有者在入境之後90天內和美國公民完婚，就可以申請在美國進行身份調整。 身份調整申請得到批准後，申請人就成為美國永久居民。身份調整申請被批准這一步也就是我們通常所說的“拿到綠卡”。</p>
      </div>
      <div class="paragraph">
        <p>K-2簽證</p>
        <p>隨著時代的變遷，當今社會的家庭團聚不同於以往。帶前一個婚姻的子女再婚變得越來越常見。為了讓子女也能隨父（母）來美，美國也因此衍生出了K-2簽證。</p>
      </div>
      <div class="paragraph">
        K-2簽證是為外國未婚夫/妻的未婚子女（21歲以下）到美國來而提供的一種簽證。這種簽證的目的是讓K-1持有者的子女在等待K-1父/母和美國公民完婚期間隨其K-1父/母居住在美國。一旦K-1父/母和美國申請人完婚，K-2簽證的持有者就可以申請身份調整。但如果要申請身份調整，K-2簽證的持有者必須是K-1簽證持有者未滿21周歲且未婚的子女。
      </div>
      <div class="paragraph">
        美國法律承認像這樣相愛的人的團聚的重要性。考慮到跨國婚戀的複雜性，K-1/K-2簽證提供了一個使美國公民和他們的外國未婚夫/妻以及外國繼子女能夠重新團聚的最佳選擇。
      </div>
    `
  },
]

export const immigrant = [
  {
    title:'職業移民',
    content:`
      <div class="paragraph bold">
        <p>Employment-based visa，是美國允許美國勞動力市場短缺的各類合格人才，通過技術或特殊人才的條件，申請美國的合法永久居住權，即綠卡，的主要移民方式。</p>
        <p>目前根據優先次序依次被分為EB-1、EB-2、EB-3、EB-4、EB-5五大類。</p>
      </div>
      <div class="paragraph bold">
        <p>每個財政年度大約有 140,000 個移民簽證可供根據其工作技能尋求移民的非公民（及其配偶和子女）。如果您擁有正確的技能、教育和/或工作經驗組合，並且符合其他條件，您可能能夠永久居住在美國。下麵列出了五種基於就業的移民簽證類別。其中，不少類別要求申請人已經獲得了美國僱主的工作機會。該雇主將被視為您的擔保人。對於某些簽證類別，在美國雇主向美國公民及移民服務局提交移民申請之前，雇主必須獲得美國勞工部 （DOL） 批准的勞工證。DOL 勞工證驗證以下內容：</p>
        <p>沒有足夠的可用、合格和願意的美國工人來填補以現行工資提供的職位</p>
        <p>雇用外國工人不會對類似就業的美國工人的工資和工作條件產生不利影響</p>
      </div>
      <div class="paragraph">
        勞工證申請是一個繁瑣費時並且花費昂貴的過程，其目的在於確定是否有合格的美國工人滿足相應職位的要求。 在勞工證申請過程中，一旦發現了滿足最低要求的美國工人，即便是出於其他與勞工證申請無關的因素考慮，外籍人士可能更加適合該職位，但外籍人士的勞工證仍有被拒絕的風險。所以免勞工證要求的EB-1A、EB-1B、EB-1C和NIW特別受到國人的青睞。
      </div>
    `
  },
  {
    title:'EB-1 美國傑出人才移民',
    children:[
      {
        title:'EB-1 美國傑出人才移民',
        content:`
          <div class="paragraph">
            職業移民第一類優先（First Preference Employment Based Immigration，簡稱EB-1）又稱“傑出人才”移民，是美國職業類移民申請中的一類，是美國移民法專門為在藝術、科學、教育、商業和運動等領域擁有傑出才能的外籍人士提供的移民途經。傑出人才移民是美國政府“招才納賢”，積極吸引招納世界各國優秀人才這一精神在移民政策中的具體體現。
          </div>
          <div class="paragraph">
            <p>EB-1申請最顯著的優勢就是移民局對於符合EB1申請條件的申請人，會豁免他們的勞工證申請。 </p>
            <p>什麼才是傑出人才呢？根據美國移民法（INA 203(b)(1)）的定義，“傑出人才”申請分以下三種類型：</p>
          </div>
          <div class="paragraph">
            <p>傑出人才（Alien of Extraordinary Ability，簡稱EB-1A） (參見美國移民法203(b)(1)(A))</p>
            <p>“傑出人才”或EB-1A主要指的是那些在科學、藝術、教育、商業、或體育五大領域中具有特殊才能，取得很高成就，並且享有國家級或國際級的聲譽與成就，其成就和貢獻在該領域得到廣泛認可的傑出專業人才。這些人應是被認為是該領域中出類拔萃的頂尖人物之一(One of that Small Percentage) 。同時申請人在獲得傑出人才綠卡後，將在美國繼續從事其領域內的工作，並且其工作會對美國社會的相關發展，帶來極大利益。EB-1A不需要申請勞工證，不需要永久性工作的承諾，申請人也可以自己申請移民。</p>
          </div>
          <div class="paragraph">
            <p>傑出教授或研究人員（Outstanding Researcher/Outstanding Professor，簡稱EB-1B） (參見美國移民法203(b)(1)(B))</p>
            <p>“傑出教授或研究人員”或EB-1B是指那些在某一特定的科學或學術領域中，取得國家級或國際性聲譽的教授或研究人員。此外，申請人還必須在某學術領域中，至少執教或從事研究工作三年以上。攻讀博士學位期間的研究工作可以算作申請EB-1B中的工作經驗。EB-1B的要求要低於EB-1A一些。雖然EB-1B不需要申請勞工證，但是，申請人必須有永久性工作的承諾，需要由美國雇主出面為申請人提出申請。博士後研究不算永久性工作的承諾。</p>
          </div>
          <div class="paragraph">
            <p>跨國公司的主管或經理（Managers and Executive Transferees，簡稱EB-1C） (參見美國移民法203(b)(1)(C))</p>
            <p>“跨國公司的主管或經理”或EB-1C則指那些被跨國公司調任到美國的高級行政人員或高級經理。該外國人須獲得美國公司永久聘任，而在此之前的三年期間內，已為在該美國公司的海外相關企業（母公司，子公司或關聯公司）工作至少一年以上。同樣，EB-1C不需要申請勞工證，但是需要有永久性工作的承諾，並需由美國雇主出面為申請人提出申請。 </p>
          </div>
          <div class="paragraph">
            雖然EB-1申請完全不需要申請勞工證。但是，各類EB-1申請還是有一些差異。在EB-1A的申請中，並不要求雇主支持，也不要求雇主的永久性工作承諾。因此，即使沒有雇主，或雇主不支持，申請人也可以直接自己申請移民並獲得綠卡。同時，申請人在遞交EB-1A申請以後，可以隨意變換工作，只需要能證明新的工作是在其申請的專業領域即。但是，在EB-1B和EB-1C申請中，都需要有雇主的支持並有永久性工作的承諾。EB-1B和EB-1C的申請人必須是美國雇主。換句話說，EB-1B和EB-1C的申請，必須有雇主，必須有雇主的同意，在遞交EB-1B或EB-1C申請以後，外籍人士不能隨意變換工作，否則EB-1B或EB-1C申請放棄。
          </div>
          <div class="paragraph">
            總的來說，EB-1對於傑出人才而言，最大的優勢在於申請程式中免去了申請勞工證這一步，從而大大縮短了整個移民申請的時間。另外，該類職業移民配額多，通常不用等待太久，因此可以在相對較短的時間內獲得美國“綠卡”。所以，EB-1傑出人才移民專案非常適合想要獲得美國永居身份的優秀人士。
          </div>
          <div class="paragraph">
            儘管現在美國EB-1的申請難度隨著申請人數的激增而水漲船高，但只要準備充分，提交全面的申請材料，獲批率還是非常有保障的。如果你也想移民美國，但不確定自己是否滿足申請條件，歡迎聯繫我們，我們會針對你的個人情況免費評估。
          </div>
        `
      },
      {
        title:'EB-1A——傑出人才介绍',
        content:`
          <div class="paragraph">
            根據美國移民法（Immigration & Nationality Act）第203（b）款（1）條（A）項的定義，“傑出人才”（Alien of Extraordinary Ability，簡稱EB-1A），又稱特殊技能人才，是“具有卓越專業能力，在其領域中少數的頂尖人物之一（Extraordinary ability means a level of expertise indicating that the individual is one of that small percentage who have risen to the very top of the field of endeavor）”。根據移民法規定，傑出人才移民申請還必須同時滿足以下條件：
          </div>
          <div class="paragraph">
            <p>申請人在科學、藝術、教 育、商業、或體育領域裏具有特殊能力，得到廣泛認可，取得很高成就，並且享有國家級或國際性的聲譽與成就，其成就和貢獻在該領域達到頂峰。申請人被認為是該領域中出類拔萃的人物之一。</p>
            <p>申請人在獲得傑出人才綠卡後，將在美國繼續從事其領域內的工作； 並且，申請人的工作將會對美國社會的相關發展，帶來極大利益。</p>
            <p>傑出人才的具體衡量標準及要求是什麼</p>
            <p>以上法律條文只提供了一個非常抽象的定義，並沒有給出“傑出人才”的具體條件和衡量標準。對於如何衡量“傑出人才”，聯邦院新推出的移民法8 CFR 204.5（h）（3）提出了從如下兩個方面的衡量標準：</p>
          </div>
          <div class="paragraph">
            <p>申請人是否曾經獲得過一項或一項以上的重大國際知名獎勵(a one-time achievement (that is, a major, international recognized award))；</p>
            <p>用第一種標準來衡量“傑出人才”，申請人只需證明他/她曾經獲得過一項以上的重大國際知名獎勵，僅憑此項國際知名獎勵，無須提供其他證據，即可滿足傑出人才的標準，輕而易舉地移民美國。至於什麼樣的國際獎項才是被移民法接受的重大國際知名獎勵，移民法並無確切定義或具體標準。一般來說，諾貝爾獎、奧斯卡獎、奧林匹克獎牌或獎盃，或國際重大發明創造，則可被接受為重大國際知名獎勵。</p>
          </div>
          <div class="paragraph">
            但是，並不是只有獲得諾貝爾獎或奧林匹克獎之類的重大國際知名大獎的人才算是傑出人才。移民法提供了另外一種相對寬鬆的衡量標準，即申請人只需符合移民法規定的十條標準中的任意三條，就可申請傑出人才移民。
          </div>
        `
      },
      {
        title:'移民法規十條申請條件',
        content:`
          <div class="paragraph">
            <p>“全國性或國際性獎項”(Nationally or Internationally Award)。 在其領域內，曾獲得稍次一些的國家獎（不一定是美國的）或國際認可獎項，證明在該領域內具有優異成就；</p>
            <p>“受邀請專業協會會員資格”(Invited Membership)。 此類專業協會由該領域的國家或國際專家評定具有傑出成就人士才能加入為會員；</p>
            <p>“媒體報導”(Published Material About You)。 專業刊物及主要商業出版物，或主要媒體上發表過關於此申請人及其相關工作的報導，或曾有重要媒體報導過申請者以及申請者在所屬專業領域內的成就（發表文章必須包括標題、日期、作者、必要時應加以翻譯）；</p>
            <p>“評審鑒定”(Judging or Review)。 在其領域內，曾經以個人或委員會成員身份擔任評審，對其專業領域或其他相關領域內的其他人的作品進行評審鑒定；</p>
            <p>“原創性的重要貢獻”(Original Contributions)。 在其領域內，曾經作出過學術研究或藝術上的獨創、原創性的重要貢獻；</p>
            <p>“學術文章”( Scholarly Articles)。 在所屬專業領域內的國內、國際刊物期刊或其他重要媒體上發表過的專業作品，如學術論文，書籍、學術文章等；</p>
            <p>“展覽或展示”( Exhibitions or Showcases)。 在國家級或具有較高聲譽或較大影響的藝術展覽會或其他場所上，舉辦作品展覽或其他展示活動；</p>
            <p>“領導作用/關鍵作用”( Leading/Critical Role)。 在工作機構、重要專業組織、團體擔任主要領導或其他重要職務；</p>
            <p>“高薪”( High Salary)。 在相關領域，申請人的薪資、報酬、或待遇遠遠高於其他同行；</p>
            <p>“商業成功”( Commercial Success)。 在表演、藝術上獲得商業方面的成功，並通過票房收入、影片、錄音帶、錄影帶、DVD等的銷售量得到反映。</p>
            <p>如果上述標準對申請人的職業不適用，申請人可以提交「類似材料」來證明申請人的成就。</p>
          </div>
          <div class="paragraph">
            由於傑出人才移民申請都不需要申請勞工證，不需要獲得雇主支持和永久性工作承諾。因此，只要符合以上傑出人才移民申請條件，申請人可自行提交移民申請，不需要雇主的支持。即使申請人身在美國、中國或其他國家，只需證明其在進入美國後會繼續從事相關領域的工作，如預期雇主的信件、未來工作具體計畫或其他相關證據，就可提交傑出人才移民申請。 同時，申請人在遞交傑出人才申請以後，可以隨意變換工作，只要新的工作是在其申請的專業領域即可。此外，在等待傑出人才批准的同時，申請人可以遞交其他類型的移民申請；例如：國家利益豁免。
          </div>
          <div class="paragraph">
            無排期的情況下，EB1申請人可以在遞交EB-1移民申請（I-140）的同時，遞交身份調整（I-485），並為自己及配偶、子女申請工作許可（I-765）和回美証(I-131)。在這種狀況下﹐即便還沒拿到綠卡﹐也能隨時出境（離開美國）旅遊或在美工作、生活。
          </div>
        `
      },
      {
        title:'EB-1A的申請流程與排期',
        content:`
          <div class="paragraph bold">
            <p>EB-1 申請流程</p>
            <p>常規的職業移民都要經過三個步驟： 1） PERM勞工申請； 2） I-140移民申請； 3）身份轉換： I-485調整身份申請（人在美國境內）或者領事管程式（人在美國境外），I485申請批准，獲得正式綠卡 。由於漫長繁瑣的勞工證申請，和不確定的移民排期，綠卡申請的時間可能長達4-6年。</p>
          </div>
          <div class="paragraph bold">
            由於傑出人才是美國政府積極吸引招納的世界各國優秀人才，因此移民法規定，滿足“傑出人才”標準的外籍人士，美國政府免除其PERM勞工證的申請，直接進行EB1移民申請（I-140申請），若提交申請時，沒有出現排期，申請人可迅速遞交I-485調整身份或者安排移民簽證面談，或在遞交EB1移民申請的同時，遞交I-485身份調整。因此EB1移民綠卡申請時間要大大少於其他類職業移民。
          </div>
          <div class="paragraph bold">
            <p>綜上所述，EB1的申請流程為：</p>
          </div>
          <div class="paragraph bold">
            <p>第一步：EB1移民申請（I-140）:</p>
            <p>申請人準備相應的材料，向移民局提交EB1移民申請，移民局審核EB1移民申請並給出裁決。</p>
          </div>
          <div class="paragraph bold">
            <p>通常情況下，申請人需要三、四個月收集準備相應的材料。I-140常規處理需要六個月到一年，也可以選擇加急申請服務（加急，通常會在15個工作日內收到審理結果），</p>
            <p>要求加速審理，加速審理應在15天之內獲得處理結果。如收到RFE補充材料通知，移民局後給30到90天時間準備補充材料回復書，移民局會在60天內給出裁決。因此，如果一切順利，申請人可能在兩周內收到EB1移民申請批准信，一般情況半年有會有結果。</p>
          </div>
          <div class="paragraph bold">
            <p>第二步：I-485調整身份：</p>
            <p>若遞交I-140時沒有排期，申請人如果在美國境內，可以選擇同步或者獲批後立馬遞交I-485調整身份；若有排期，則申請人需要等到排期到了，才能通過遞交I-485調整身份，或者完成領事館程序（若申請人不在美國），這個階段通常需要6-8個月完成。</p>
          </div>
          <div class="paragraph bold">
            <p>EB-1排期</p>
            <p>想瞭解EB-1排期 ，我們從職業移民的名額說起。每年移民局INA分配給職業移民14萬名移民簽證。INA進一步規定，每年每個國家的移民名額限制為7%。如果其他國家的移民名額沒有用完，可以平均分配給移民名額已用完的國家。最新排期查詢：<a href="https://travel.state.gov/content/travel/en/legal/visa-law0/visa-bulletin.html">https://travel.state.gov/content/travel/en/legal/visa-law0/visa-bulletin.html</a></p>
          </div>
        `
      }
    ]
  },
  {
    title:'EB-1B——傑出教授/研究學者', // 这里有一大段还没放进来
    children:[
      {
        title:'EB-1B——傑出教授/研究學者',
        content:`
          <div class="paragraph">
            根據美國移民法（Immigration & Nationality Act）第203(b)款(1)條(B)項的定義，「傑出教授/研究人員」（Outstanding Researcher/Outstanding Professor，簡稱EB-1B）移民申請，是由雇主出面擔保申請的，主要適用於在某些科學或學術領域內，享有國際聲譽的傑出教授或研究人員的移民申請。
          </div>
          <div class="paragraph">
            與EB-1A申請無需雇主支持不同，EB-1B必須有雇主支持才能申請，而且在整個申請過程中都需要雇主支持。在EB-1B申請中，雇主是申請人，外籍的傑出教授/研究人員是受益人。
          </div>
          <div class="paragraph">
            <p>傑出教授/研究人員的申請資格</p>
            <p>根據移民法規定，無論是居住在美國境內還是境外，符合「傑出教授/研究人員」標準的外國人必須證明如下三個條件：</p>
          </div>
          <div class="paragraph">
            <p>在某特定的科學或學術領域中,獲得國際性認可的成就；</p>
            <p>在某特定的科學或學術領域中，有三年（及）以上的相關研究經驗或教學經驗；</p>
            <p>申請人攻讀高等學位（比如Ph. D博士學位）期間的研究或教學經驗可以累積其中，但僅適用於以下情況：該外籍人士必須已經拿到該學位；該外籍人士在此期間對教學班級負全責；</p>
            <p>抑或，該外籍人士的研究在其學術領域被視為傑出的研究。</p>
          </div>
          <div class="paragraph">
            申請人必須從現任及前任雇主處獲得工作證明，以書面形式遞交，內容包括該外籍人士的工作職責和雇傭期限等。
          </div>
          <div class="paragraph">
            <p>由雇主支持申請，獲得永久性研究職位或終身教學職位的承諾；</p>
            <p>一般來說，永久性工作承諾一般由大學、學術或科研機構提供，但也可以由私人雇主提供。</p>
            <p>不過，該私人雇主必須已雇傭至少三名全職研究員從事研究工作，並提供材料證明其在某學術或科研領域內已獲得了哪些成就。</p>
          </div>
          <div class="paragraph">
            綜上所述，如果外籍受益人在自己相關的領域內，有三年以上的研究或教學經驗，獲得雇主的永久性工作承諾，並由雇主出面擔保申請的話，就可以通過EB-1B的形式，直接申請綠卡，不需要申請勞工證。
          </div>
        `
      },
      {
        title:'EB-1B常见问题',
        content:`
          <div class="paragraph">
            <p>什麼是永久性工作承諾？</p>
            <p>永久性工作承諾是指雇主承諾提供的一個沒有確定終止日期的工作職位。 雇員在拿到這個承諾之後，可以合理的預期自己將在這個職位上長久的工作。現實中，除了明確規定終止期限的工作職位外（如合同工），大部分工作都可以被視為是永久工作。兼職工作也可以被視為是永久性的，只要這份工作沒有規定具體的結束時間。 如果您需要進一步瞭解有關EB-1B永久性工作承諾的資訊，請點擊雇主擔保申請及移民局對EB-1B 中的“永久工作”的解釋。</p>
          </div>
          <div class="paragraph">
            <p>EB-1B綠卡申請所需要的證明材料</p>
            <p>同EB-1類其他移民申請一樣，EB-1B申請也無需申請勞工證，但外籍受益人必須從雇主處獲得永久性工作承諾，並且由雇主出面提交移民申請。</p>
          </div>
          <div class="paragraph">
            以上法律條文只提供了一個非常抽象的定義，並沒有給出“傑出教授/研究人員”的具體條件和衡量標準。移民法8 CFR 204.5(i)(3)(i)提出了如下六條具體的衡量標準。根據移民法規定，EB-1B申請人需要提交以下六條證明材料中的至少兩項：
          </div>
          <div class="paragraph">
            <p>“重大獎項或名譽”(Major Prizes or Awards)。 因傑出成就而獲得重大獎項或名譽；</p>
            <p>“受邀請專業協會會員資格”(Invited Membership)。 擁有只有傑出成就的人士才能加入的協會組織的會員資格；</p>
            <p>“媒體的報導”(Published Material About You)。 在專業出版物及主要商業出版物上，有報導該外籍人士的成就的文章（並不僅僅是引用外籍人士的作品或成果，發表文章必須包括標題、日期、作者、必要時應加以翻譯）；</p>
            <p>“評審鑒定”(Judging or Review)。 以個人或團體的形式，參與評審業內其他人的作品；</p>
            <p>“學術文章”(Scholarly Articles)。 在國際發行的期刊上發表過專業作品，如學術論文，書籍、學術文章等；</p>
            <p>“原創性的重要貢獻”(Original Contributions)。 在所在的專業領域，作出有原創性的科學、學術或藝術貢獻。</p>
            <p>如果上述標準對外籍受益人不適用，申請人可以提交「類似材料」來證明受益人的條件。</p>
          </div>
          <div class="paragraph">
            另外，在EB1B綠卡申請過程，雖然不需要申請勞工證，但必須獲得雇主的永久性工作承諾，並由雇主支持，出面擔保申請，所以EB1B申請是跟雇主掛鉤的。對雇主要求也是EB1A綠卡申請和EB1B綠卡申請的一個重要區別。因此，在提交EB1B綠卡的（I-140）申請過程中，一旦外籍受益人更換了雇主，從理論上講，原本正在處理中的EB1B綠卡申請就有因此而終止。
          </div>
          <div class="paragraph">
            一般情況下，如果您的條件比較好，我們更推薦申請 “傑出人才”EB1A綠卡，因為EB1A的申請不需雇主支持，申請人不會被限制在支持提交申請的特定雇主和工作職位上。
          </div>
        `
      }
    ]
  },
  {
    title:'EB-1C—跨國公司高管', // 这里有一大段还没放进来
    content:`
      <div class="paragraph">
        EB-1C 是美國職業移民第一類優先EB-1的三種類型的其中一種。在EB1C的移民申請過程中，跨國公司（即美國雇主）是EB1C綠卡申請的申請人，外籍高級行政管理人員是EB1C綠卡申請的受益人。為了使那些在藝術、商業、教育和科學領域中具有非凡能力和成就的人快速實現移民目的，美國移民法規定只要滿足EB-1標準的外籍人士，不需要申請勞工證，就可以直接申請移民並獲得綠卡，即獲得美國永久居住權。從移民排期方面考慮，申請EB-1有一個非常有利的條件，即一般無移民簽證排期。每年美國全球EB-1職業移民的簽證配額大約為4萬個。在EB-1申請批准後，在美國境內的外籍人士可以向移民局提出I-485身份調整申請；若在境外則可向境外的美國使領館申請領事處理程式（consular processing），任一申請被批准後就可以獲得美國綠卡。
      </div>
      <div class="paragraph">
        <p>對於作為EB1C綠卡的申請人的跨國公司（美國雇主）的要求</p>
        <p>雇主必須是跨國公司</p>
        <p>在EB1C綠卡的申請中，作為申請人的跨國公司必須至少擁有兩個機構或公司， 一個是在美國境內的機構或公司， 一個是美國境外的機構或公司， 而且美國境內的公司和境外的公司之間必須合服移民法規定的合格的公司關係 （Qualifying Relationship）， 這樣的關係包括：如母公司、子公司、辦事處及附屬公司。 如果你希望瞭解更多關於合格的公司關係，請點擊L1簽證對美國境內外公司的關係要求。</p>
      </div>
      <div class="paragraph">
        <p>雇主必須在美國境內外正常運營</p>
        <p>在美國境內的機構和境外機構都必須進行業務運營，提供經常，連續，系統的商品和服務。跨國公司經營需要切實的(Viable)，有經營效益的，不要求一定涉及國際貿易，跨國公司的經營可以是企業、非營利組織、宗教或慈善機構。注意僅僅是在美國境內或境外設有辦公室或聘請代理人並不能滿足要求。如果你希望瞭解更多關於合格的公司，請點擊L1簽證對公司的要求。</p>
      </div>
      <div class="paragraph">
        <p>雇主必須在美國已經存在至少一年。</p>
        <p>對於作為EB1C移民申請的外籍受益人的調遣雇員（高級經理和行政主管）的要求</p>
        <p>EB1C所說的高管職位，是要有實際管理職能的。光是企業法人，老闆或股東，但沒有參與公司的日常管理，還不行。高管包括了高級執行官（executive officer）或高級經理（managerial officer）。作為EB1C受益人的高級經理和行政主管需要滿足如下條件：</p>
      </div>
      <div class="paragraph">
        <p>該受益人必須在申請人提交申請前的三年內至少連續一年在美國境外的關聯公司（如母公司、子公司或者分公司）工作。在美國境內為跨國公司工作的時間和為跨國公司來美國進行的短期商務旅行時間雖然不算打斷一年工作的連續性，但也不計算在境外工作的一年時間內；</p>
        <p>該受益人在美國境內跨國公司必須擔任高級經理或者行政主管職位；</p>
        <p>僅僅是職務名稱中含有「經理」或者「主管」字樣是不夠的，該職務的職責描述必須滿足法律定義中的要求。</p>
      </div>
      <div class="paragraph">
        <p>高級經理管理性職責（Managerial Capacity）主要包括：</p>
        <p>管理公司內部某些組織，部門，公司的功能或公司的組成部分；</p>
        <p>監督和掌管的其他監督部門，管理人員或專業技術人員，或管理公司內部的一項必要功能；對其管理和監督的員工有人事任免權力，罷免和解雇的權力，或者有人事建議權（對於其直接監督的員工），或者管理組織內部的一個核心部門；</p>
        <p>在日常經營管理中，有能夠自由裁量的權力。</p>
        <p>如果某低級主管還直接接受其他更高級主管的管理，該低級主管一般不符合EB1C的要求。</p>
      </div>
      <div class="paragraph">
        <p>行政主管決策性職責（Executive Capacity）主要包括：</p>
        <p>指導公司內部的管理，指導公司某個主要部門或功能的管理工作；</p>
        <p>制定公司或主要部門的工作目標和工作規劃；</p>
        <p>在公司決策方面擁有很大的自主裁決權；</p>
        <p>只接受董事會或者股東會或者上一級決策人員的一般的監督或指導。</p>
        <p>雖然移民法和移民局未對EB1C受益人的教育背景和工作經驗提出具體的要求，但是申請人必須證明EB1C受益人的教育水準和工作經驗能夠與其職位相符。</p>
      </div>
    `
  },
  {
    title: `EB-2 高學歷人才`,
    children: [
      {
        title: `NIW——EB-2的特殊類別`,
        content:
        `
          <div class="paragraph">國家利益豁免移民（National Interest Waiver，簡稱NIW）是美國移民法職業移民第二類優先（EB-2）的一個特例。第二優先EB2移民申請要求申請人必須在科學、技術、教育、藝術和商務等領域擁有 “高等學位” 或具有 “特殊技能” 的人才，比如工程師、醫生、研究人員、藝術家等。</div>
          <div class="paragraph">一般來說，正常的第二優先EB2移民申請要求申請人不但需要有美國雇主，而且需要美國雇主提供永久性的工作承諾（Permanent Job Offer），並由雇主出面為其向勞工部（Department of Labor，簡稱DOL）申請勞工證（Labor Certification，簡稱LC）。在勞工證獲批以後，再為其向移民局提出移民申請（I-140）。並且在整個移民申請過程中，申請人需為其雇主工作，一旦申請人需要離開或變換工作，所有移民申請全部作廢。</div>
          <div class="paragraph">但是， NIW綠卡申請是EB2綠卡申請的特例，美國政府基於國家利益的考慮，對那些為美國國家利益做出重要貢獻的外籍人士，豁免他們的永久性工作的承諾要求及漫長繁瑣的勞工證申請，使他們可以直接向移民局提出移民申請獲得綠卡。</div>
          <div class="paragraph">什麼樣的申請人能夠滿足NIW申請條件呢？究竟申請人需要提供什麼樣的具體材料才能滿足NIW的要求？詳情可以查看下我司的其他文章，或聯繫我們，我們可以為您評估。</div>
        `
      },
      {
        title: `申請國家利益豁免的的基本條件`,
        content:
        `
          <div class="paragraph">由於國家利益豁免屬於職業移民第二優先（EB-2），因此申請人必須符合申請EB-2的條件，也就是擁有 “高等學位” （Professionals Holding Advanced Degrees）或者“特殊技能” （Aliens of Exceptional Ability）， 同時還需要在 “高等學位”的工作領域內工作，或者是在傳統的專業領域內工作，比如律師、醫生、建築師、工程師或教師。</div>
          <div class="paragraph">“高等學位” 即指以下任意一條：</div>
          <div class="paragraph">
            <p>擁有美國大學的碩士及以上學歷；</p>
            <p>擁有外國同等大學的碩士及以上學歷；</p>
            <p>擁有美國或外國同等大學的學士學位，並同時擁有5年以上相關專業領域的工作經驗（這兩者綜合起來，視為等同於碩士學位）。</p>
            <p>如果沒有以上的“高等學位”，申請人也可以宣稱擁有“特殊技能”，也就是在科學、藝術或商業領域，擁有超過一般同行的特殊技能。“特殊技能” 要求滿足以下條件中的至少三條：</p>
          </div>
          <div class="paragraph">
            <p>在其專業領域內曾經獲得過學院、大學或其他教育培訓機構頒發的官方學術證明；</p>
            <p>在其專業領域內有十年以上的全職工作經驗，這一項一般需由前任及現任雇主提供證明；</p>
            <p>有在相關領域的專業從業執照；</p>
            <p>申請人由於自己的「特殊技能」所獲得薪水或其他報酬；</p>
            <p>申請人是專業協會的會員； 或者；</p>
            <p>在所在的領域內獲得認可，並做出重要的貢獻。這一點由同事、政府機關、專業或者商業組織提供證明。</p>
            <p>申請人只需要符合 “高等學位” 或者 “特殊技能” 其中一個條件既可，不必同時符合兩個條件。如果需要瞭解更多關於 “高等學位” 和 “特殊技能” 的資訊，請點擊《高等學位 vs. 特殊技能》。</p>
          </div>
        `
      },
      {
        title: '申請國家利益豁免的附加條件',
        content:
         `
          <div class="paragraph">
            當然，美國移民局行政上訴辦公室（AAO）表示，申請人只是擁有 “高等學位”或者“特殊技能” 並不足以滿足NIW申請條件的，申請人還必須同時滿足在Dhanasar案例（以前是NYSDOT案例，從12/27/2016起，是Dhanasar案例）中所制定的具體標準，即Dhanasar三重標準：
          </div>
          <div class="paragraph">
            <p> 申請人所從事的專業領域必須同時具有實質性價值， 並在美國全國範圍內帶來重大利益；</p>
            <p> 首先，在新的第一標準決定中，“工作領域” （Proposed Endeavor）是申請人個人研究工作，而不是其整個領域。因此我們只需要證明申請人的個人研究工作具有實質性價值，而不需要證明整個領域。一般說來，幾乎所有的工作都具有實質性價值，各科研領域、工程、藝術、學術、教育、金融、體育、出版、電影、音樂等均對國家具有實質性價值，想要證明這一點是比較容易的。</p>
          </div>
          <div class="paragraph">
            另外，關於給全國帶來重大利益，AAO提到這樣一個事實，研究成就可能是具有國家或全球影響，即使其研究活動可能是在某一區域。 這意味著，在新的標準中，在工業界工作的申請人，如工程師，經理或顧問， 如果他們能夠證明他們提出的領域會給國家帶來重大利益，即使沒有出版論文，也可能符合NIW申請條件。 不過，教師，律師，醫生是不太可能符合EB-2 NIW申請的標準的。
          </div>
          <div class="paragraph">
            <p> 申請人以有貢獻和成就處於推動其領域發展的有利位置；</p>
            <p> 在評估一個人是否處於“有利位置”（Well Positioned）時，移民局側重於“教育背景，技能，知識，以有成就；未來研究計畫或示範；研究成果；以及潛在客戶，用戶，投資者或其他相關實體或個人的興趣。”</p>
          </div>
          <div class="paragraph">
            申請人必須證明，豁免工作的承諾和勞工證要求，是有益於美國國家利益。一般而言，美國移民局會從以下三個方面考慮免除對申請人的勞工證要求是否符合美國國家利益。
          </div>
          <div class="paragraph">
            <p>第一方面：擬議的努力既有實質性的優點，又有國家重要性</p>
          </div>

          <div class="paragraph">在審查擬議的努力時，官員會確定所提供的證據是否通過優勢證據證明擬議的努力具有重大價值和國家重要性。“努力”一詞比一般職業更具體;請願人不僅應詳細說明該職業通常涉及什麼，還應提供該人打算在該職業中具體從事哪些類型的工作的詳細資訊。例如，雖然工程是一種職業，但對擬議工作的解釋應該描述具體的專案和目標，或該人將從事的工程領域，而不是簡單地列出工程師的職責和責任。</div>

          <div class="paragraph">這項努力的優點可以在包括但不限於商業、創業、科學、技術、文化、健康或教育等領域得到證明。</div>

          <div class="paragraph">此外，官員們可能會考慮該努力可能產生重大經濟影響的證據，但“可以在沒有直接或可量化的經濟影響的情況下確定功績”，並且“與研究、純科學和促進人類知識相關的努力可能符合條件，無論這些領域的潛在成就是否可能轉化為美國的經濟利益。</div>

          <div class="paragraph">官員們還必須通過考慮其潛在的預期影響來審查所提議的具體努力的國家重要性。官員應關注擬議工作的性質，而不僅僅是工作的地理廣度。</div>

          <div class="paragraph">例如，這項努力“可能具有國家重要性，因為它在特定領域具有國家甚至全球影響，例如某些改進的製造工藝或醫學進步。在經濟上，它可能具有“雇用美國工人的巨大潛力”或“其他實質性的積極經濟影響，特別是在經濟蕭條的地區”。因此，請願人應提交一份詳細的描述，解釋擬議的努力和支持性檔證據，以證明該努力具有國家重要性。</div>

          <div class="paragraph">在確定國家重要性時，官員的分析應側重於受益人將要做什麼，而不是具體的職業分類。例如，課堂教學等對某個領域或地區沒有更廣泛影響的努力，通常不會上升到具有國家重要性的程度，無法確定國家利益豁免的資格。</div>

          <div class="paragraph">最終，如果記錄證據表明，該人擬議的努力具有廣泛提高社會福利或文化或藝術豐富的巨大潛力，或有助於推動有價值的技術或研究領域的進步，則它可能會上升到國家重要性的水準。</div>

          <div class="paragraph">第二個方面：該人處於有利地位，可以推進擬議的努力</div>

          <div class="paragraph">與第一個方面不同，第一個方面側重於擬議努力的優點和重要性，而第二個方面則以人為中心。具體來說，請願人必須證明該人有能力推進這項工作。</div>

          <div class="paragraph">在評估此人是否有能力推進這項工作時，美國公民及移民服務局會考慮各種因素包括但不限於：</div>

          <div class="paragraph">該人的教育、技能、知識和在相關或類似工作中的成功記錄;</div>

          <div class="paragraph">該人為與擬議工作相關的未來活動制定或在制定中發揮重要作用的模型或計畫;</div>

          <div class="paragraph">在實現擬議努力方面取得的任何進展;和</div>

          <div class="paragraph">該人從潛在客戶、用戶、投資者或其他相關實體或個人那裏獲得的興趣或支持。</div>

          <div class="paragraph">請願人應提交證據，以記錄該人過去的成就，並證實與擬議努力有關的預測，以表明該人有能力推進這項工作。一個人可能處於有利地位，可以推進一項努力，即使這個人不能證明擬議的努力更有可能最終成功。然而，未經證實或難以置信的主張將不符合請願人的舉證責任。</div>

          <div class="paragraph">以下是一份非詳盡的證據類型列表，這些證據往往表明該人有能力推進擬議的努力。此列表並不意味著是一份清單，也不是為了表明任何一種類型的證據是確定資格所必需的或充分的。</div>

          <div class="paragraph">可以證明該人有能力推進擬議工作的證據包括但不限於：</div>

          <div class="paragraph">該領域的學位、證書或執照;</div>

          <div class="paragraph">由該人開發的專利、商標或版權;</div>

          <div class="paragraph">來自該人所在領域專家的來信，描述該人過去的成就，並提供具體的例子，說明該人如何處於有利地位以推進該人的努力;</div>

          <div class="paragraph">發表有關該人的成就或當前工作的文章或媒體報導;</div>

          <div class="paragraph">證明該人作品有很強的引用歷史的檔，或已發表文章的摘錄，顯示圍繞該人的作品進行積極討論或採用;</div>

          <div class="paragraph">證明該人的工作影響了工作領域的證據;</div>

          <div class="paragraph">描述該人打算如何在美國繼續擬議工作的計畫;</div>

          <div class="paragraph">在適當的情況下，提供詳細的商業計畫或其他描述，以及任何相關的支持證據;</div>

          <div class="paragraph">來自潛在或潛在雇主、客戶或客戶的信件;</div>

          <div class="paragraph">反映可行的財政支持計畫的檔（有關與企業家融資有關的證據的更詳細討論，見下文）;</div>

          <div class="paragraph">證明該人已獲得美國投資者（如風險投資公司、天使投資人或創業加速器）的投資，並且金額與相關努力相適應的證據;</div>

          <div class="paragraph">顯示擬議工作的潛在影響的合同、協議或許可證的副本;</div>

          <div class="paragraph">來自美國政府機構或准政府實體的信函，證明該人完全有能力推進擬議的努力（有關有關政府機構和准政府實體的支持證據的更詳細討論，請參見下文）;</div>

          <div class="paragraph">證明該人已從具有經濟發展、研發或創造就業機會專業知識的聯邦、州或地方政府實體獲得獎勵或贈款或其他相關非貨幣支持（例如，免費使用設施）的跡象;和</div>

          <div class="paragraph">證明他人如何使用此人作品的證據，例如但不限於：</div>

          <div class="paragraph">與使用該人開發或協助開發的產品的公司簽訂的合同;</div>

          <div class="paragraph">顯示該人發明或參與發明的技術以及他人如何使用該技術的檔;和</div>

          <div class="paragraph">創新的專利或許可，該人開發的檔表明該專利或許可對該領域的重要性。</div>

          <div class="paragraph">在每宗案件中，警務人員必須考慮整體情況，以確定是否有大量證據證明該人有能力推進擬議的工作。</div>

          <div class="paragraph">第三方面：總的來說，放棄工作機會，從而放棄永久勞工證要求對美國有利</div>
         `
      },
      {
        title: '如何才算滿足NIW中高學歷和特殊技能的條件？',
        content:
        `
          <div class="paragraph">由於國家利益豁免屬於職業移民第二優先（EB-2），申請人必須擁有「高等學位」，即指美國或外國同等大學的碩士或以上學位學歷，比如M.A.，M.S.，M.E.，M.D.或Ph.D.；或者擁有「特殊技能」（Aliens of Exceptional Ability），即在自身的專業領域中擁有超過一般同行的特殊技能。 申請人只需要符合「高等學位」或者「特殊技能」其中一個條件既可，不必同時符合兩個條件。以下將分別說明兩個條件的具體材料要求。</div>

          <div class="paragraph">「高等學位」所需證明材料</div>

          <div class="paragraph">大多數國家利益豁免移民的申請人滿足「高等學位」這一條件，而非「特殊技能」。對於擁有高等學位的EB-2申請人來說，要想獲得綠卡，往往需要先申請勞工證（Labor Certification），美國雇主的工作承諾也是必不可少的。通過國家利益豁免申請綠卡無須滿足以上標準，不過，申請人在遞交移民申請表格（I-140）的同時，還需要提供以下兩項之一：</div>

          <div class="paragraph">官方學歷證書，證明申請人擁有美國高等學歷，或者高於美國本科水準的海外學歷 或</div>

          <div class="paragraph">官方學歷證書，證明申請人擁有相當於美國本科水準的本科學歷；以及來自於過去/目前雇主的推薦信，證明申請人在其專業領域擁有五年以上的工作經驗</div>

          <div class="paragraph">「特殊技能」所需證明材料</div>

          <div class="paragraph">通過「特殊技能」條件申請國家利益豁免移民的申請人，必須在自身的專業領域中擁有超過一般同行的特殊技能。申請人還需要進一步證明，由於其在科學、藝術或商業領域擁有的特殊技能，他/她的工作和貢獻可以為全美國的經濟、文化、教育帶來巨大的利益。申請人需要滿足以下要求中的至少三條：</div>

          <div class="paragraph">在其專業領域內曾經獲得過學院、大學或其他教育培訓機構頒發的官方學術證明；</div>

          <div class="paragraph">證明其在專業領域內有十年以上全職工作經驗的推薦信，這一項一般需由前任及現任雇主提供證明，寫推薦信的人必須列上自己的名字、頭銜和機構名稱；</div>

          <div class="paragraph">有在相關領域的專業從業執照；</div>

          <div class="paragraph">專業協會會員的證明，會員資格的取得需基於個人能力；</div>

          <div class="paragraph">申請人的專業技能被所在領域的同事、同行或者業內組織認可的證據，這包括，發表的研究文章、學術期刊引用、摘要、學術會議邀請函、期刊編輯或他人對申請人發表文章的評論；或者</div>

          <div class="paragraph">申請人由於自己的「特殊技能」所獲得薪水或其他報酬資助，這包括，資助研究專案的獎勵經費等等。</div>

          <div class="paragraph">請注意，「高等學位」或者「特殊技能」只是申請國家利益豁免的「基本標準」，滿足該基本標準並不保證申請人一定能獲得國家利益豁免申請的批准。要想成功獲批，申請人還必須滿足其他的一些實質性要求。</div>

        `
      },
      {
        title: '什麼樣的NIW申請材料才是美國移民局最想要的？',
        content:
        `
          <div class="paragraph">移民局官員在審理一份國家利益豁免申請時，非常看重申請人過去在其工作領域中曾有過的成就和貢獻的相關證明文件。那麼如何衡量申請人過去的成就是否符合移民申請的要求呢？ 申請人過去的成就必須滿足Dhanasar案規定的三條標準：</div>
          <div class="paragraph">申請人的專業研究領域有實質性價值和國家性的重要意義；</div>
          <div class="paragraph">申請人處於推動本領域進展的有利位置；</div>
          <div class="paragraph">平衡各種因素後，申請人被豁免工作機會和勞工證要求會有益於美國的國家利益；</div>
          <div class="paragraph">總的來說，申請人最終需要證明的就是，相較於具備同等資格的美國勞工，他/她的工作和貢獻可以為全美國帶來更大的利益。</div>
          <div class="paragraph">支持證明文件應包含以下材料：</div>
          <div class="paragraph">所屬專業領域的專家推薦信。這裏的專家包括以下人等：</div>
          <div class="paragraph">申請人曾在其平臺發表過研究文章的期刊/雜誌社編輯；</div>
          <div class="paragraph">引用過申請人研究作品的同領域其他研究人員；</div>
          <div class="paragraph">將申請人研究成果商業化的同領域其他研究人員；</div>
          <div class="paragraph">同領域的政府研究人員或其他認證機構研究人員；</div>
          <div class="paragraph">對申請人的研究起過指導作用的顧問專家；</div>
          <div class="paragraph">關於申請人所做過的有價值貢獻的實質證明； 比如，申請人可以提供相關記錄檔，以及一些描述性的檔，證明他/她曾在其工作領域的某具體專案上起到關鍵性作用，對該專案的進展和成功完成居功至偉。這些記錄越一致，申請人的職位越重要（或其曾參與的專案越受認可），則其被移民局認可的可能性就越大，申請獲批的幾率也就越大。實質證明檔應包括：</div>
          <div class="paragraph">申請人獨立或與人合著的發表文章的數量與刊物排名；</div>
          <div class="paragraph">申請人獨立或與人合著的發表文章被其他研究人員引用的次數；關於引用次數的資訊能夠在Google Scholar或Web of Science上檢索到。</div>
          <div class="paragraph">被其他研究人員獨立引用次數比被自己重複引用的次數更具說服力，因為後者並不能說明申請人對其專業領域產生了何等深遠的影響，也不能說明較於同行，申請人的研究成果有何獨到之處。而獨立引用次數則說明了業界其他研究人員對於申請人的研究成果是如何的重視態度。獨立引用次數越高，對申請人成功申請移民越有幫助。</div>
          <div class="paragraph">申請人曾投身商業事務，以及推動經濟發展的證明等；</div>
          <div class="paragraph">關於其研究成果或研究進展，申請人收到的重印要求或進一步要求等；</div>
          <div class="paragraph">來自被業界承認的期刊或組織的認可信或邀請函；</div>
          <div class="paragraph">申請人曾在其所屬領域獲得過的各種獎項、榮譽或證書的影本，以及相關評選標準檔；</div>
          <div class="paragraph">專業協會的會員資格； 此類專業協會由該領域專家評定為傑出成就人士才能加入為會員；</div>
          <div class="paragraph">曾參與評審團，評論過同專業領域中其他人的工作；</div>
          <div class="paragraph">關於申請人的研究成果的媒體報導影本；</div>
          <div class="paragraph">申請人在其領域獲得過的重大國際性的成就或獎項（如諾貝爾獎）等；</div>
          <div class="paragraph">如申請人來自表演藝術領域，獲得商業方面成功的證據，可通過票房收入、銷量等記錄反映；</div>
          <div class="paragraph">申請人發表的作品在網路上被大量下載；換言之，即使申請人的作品沒有被大量引用，但其作品的線上下載率特別高的話也可作為相關證明材料；</div>
          <div class="paragraph">申請人的專利實施許可證明（如果有的話）；</div>
          <div class="paragraph">其他類似相關證明；</div>
          <div class="paragraph">請注意，上述證明材料並非都是必要的，且其中任何一條證明要求都不完全具有決定性。一般來說，證明材料越多越好。但是，需要強調的是，移民局更看重的是材料的品質，而不是數量。</div>
          <div class="paragraph">在國家利益豁免申請過程中，很重要但經常被忽視的一個關鍵環節是如何有效的組織證明材料。一份雜亂無章的申請可能會加大移民局官員的審核難度，讓移民官找不到重點，不利於申請的成功獲批。因此，如何準備好你的申請材料並讓移民官能快速掌握申請人的個人優勢與能力是非常關鍵的。</div>
        `
      },
      {
        title: '和NIW有關的常見問答',
        content:
        `
          <div class="paragraph">問： 什麼是國家利益豁免？ </div>

          <div class="paragraph">答： 國家利益豁免（National Interest Waiver，簡稱NIW）屬於職業移民第二優先中的的一個特例。通常情況下，職業移民第二優先需要雇主提供永久工作職位，並且在遞交移民申請之前要事先獲得批准的勞工證。但是，美國政府基於美國國家利益的考慮，優先照顧對美國利益有貢獻的外籍人士，使得符合特定條件的外籍人士，通過國家利益豁免這一管道，在無需雇主以及勞工證的情況下可以直接申請並獲得綠卡。</div>

          <div class="paragraph">問： 國家利益豁免申請的最低要求是什麼？ </div>

          <div class="paragraph">答： 由於國家利益豁免屬於職業移民第二優先中的的一個特例，申請人必須是擁有「高等學位（碩士或以上學位）」或者「特殊技能」的外籍人士。但是請注意，滿足最低標準並不證明就可以獲得申請的批准。</div>

          <div class="paragraph">問： 什麼是「高等學位」？ </div>

          <div class="paragraph">答： 高等學位即指碩士或以上學位，比如M.A.，M.S.，M.E.，M.D.或Ph.D.。申請人必須擁有美國大學碩士或以上學位或者國外其他大學的同等學歷，並且在需要在學士學位以上的工作領域內工作，或者是在傳統的專業領域內工作，比如律師、醫生、建築師、工程師或教師。僅有學士學位的申請人另加五年專業領域的工作經驗也算滿足「高等學位」的要求。</div>

          <div class="paragraph">問： 什麼是「特殊技能」？ </div>

          <div class="paragraph">答： 根據美國移民局相關規定，擁有「特殊技能」的外籍人士指的是在科學、藝術或商業等領域擁有突出技能，或者滿足以下要求中的至少三條：</div>

          <div class="paragraph">
            <p>● 在其專業領域內曾經獲得過學院、大學或其他教育培訓機構頒發的官方學術證明；</p>
            <p>● 在其專業領域內有十年以上的全職工作經驗，這一項一般需由前任及現任雇主提供證明；</p>
            <p>● 有在相關領域的專業從業執照；</p>
            <p>● 申請人由於自己的「特殊技能」所獲得薪水或其他報酬；</p>
            <p>● 申請人是專業協會的會員；</p>
            <p>● 在所屬領域內獲得的成就和重要的貢獻。這一點由同事、政府機關、專業或者商業組織提供證明。</p>
          </div>

          <div class="paragraph">問： 要申請國家利益豁免，我必須同時滿足「高等學位」和「特殊技能」兩個條件嗎？</div>

          <div class="paragraph">答： 不。以前大家有一些誤解，認為申請國家利益豁免要同時滿足這兩個條件。其實，申請人只需證明自己擁有「高等學位」，或者是「特殊技能」即可。證明自己同時滿足兩個條件是沒有必要的。而且請注意，這只是申請國家利益豁免的「基本標準」，並不能保證國家利益豁免申請一定會取得成功。</div>

          <div class="paragraph">問： 申請國家利益豁免都需要準備哪些文件？</div>

          <div class="paragraph">答： 申請國家利益豁免需要遞交移民局I-140表格和勞工部ETA-750B表格，以及能夠證明申請人是擁有「高等學位」或「特殊技能」外籍人才的相關材料。除此之外，根據移民法規定，沒有其他必交的資料。但是，我們建議申請人還應遞交由申請人所在的領域內的傑出人士出具的推薦信和由資深移民律師起草的申請書，這些材料也是相當重要的。</div>

          <div class="paragraph">在這裏，我們再次提醒您注意，僅滿足國家利益豁免申請對檔資料的最低要求並不能保證您的國家利益豁免申請一定會取得成功。</div>

          <div class="paragraph">問：在美國現行移民法下，移民局關於審核國家利益豁免申請有何標準？</div>

          <div class="paragraph">答：美國“1990年移民法”規定，對於擁有“高等學位”或“特殊技能”，且能給美國全社會帶來利益的外籍人士，豁免其勞工證申請程式。想全面瞭解相關法律條例的資訊，請點擊這裏 8 U.S.C. § 1153(b)(i)。（如果您已閱讀相關條例，就能瞭解到該條例提及到美國司法部長。此法通過後，所有移民申請統一由移民及歸化服務局（Immigration and Naturalization，簡稱“INS”）處理。移民及歸化服務局當時隸屬美國司法部。司法部長作為司法部的最高行政長官，擁有設立移民申請審查標準的權利。自9.11事件後，美國政府設立了美國國土安全部（Department of Homeland Security，簡稱“DHS”），司發部長關於移民審查的責任和權利便轉移至國土安全部。而後，移民及歸化服務局便被歸入到國土安全部，成為了現在的美國公民及移民服務局USCIS。</div>

          <div class="paragraph">問：移民局對國家利益豁免申請是如何做出最後的處理決定的？</div>

          <div class="paragraph">答： 在考慮一名外籍人士是否對美國的國家利益會帶來實質性的有益影響時，以下三個因素比較重要：</div>

          <div class="paragraph">申請人的專業研究領域有實質性價值和國家性的重要意義；</div>

          <div class="paragraph">申請人處於推動本領域進展的有利位置；</div>

          <div class="paragraph">平衡各種因素後，申請人被豁免工作機會和勞工證要求會有益於美國的國家利益；</div>

          <div class="paragraph">勞工證申請過程是為了保護與申請人有相同資格的美國人的利益。申請人（或其雇主）必須證明由申請人擔任這一職位可以給美國的國家利益帶來的有益影響將比由美國員工擔任這一職位所所帶來的影響大得多。</div>

          <div class="paragraph">在1998年之前，美國移民局USCIS（當時稱作移民歸化局INS）對國家利益豁免採取“寬大”政策。但是，根據移民局行政上訴辦公室（Administrative Appeal Office，簡稱“AAO”）對1998年8月7日的紐約交通部案（NYSDOT）做出的處理決定，國家利益豁免申請的標準被提高了。2016年12月27日，行政上訴辦公室（AAO）頒佈了對國家利益豁免（NIW）申請的新判決（Matter of Dhanasar）。此判決中規定的新標準將取代先前在紐約交通部案（NYSDOT）的舊標準，使得用於審查國家利益豁免申請的依據變得更加清晰、明確。</div>

          <div class="paragraph">問： 國家利益豁免申請能否成功是否會非常確定？</div>

          <div class="paragraph">答： 國家利益豁免申請的要求是比較抽像的，沒有法律或條例說明申請成功或失敗的硬性標準。申請人及代理律師需通過提交證明材料的方式來說明自己是符合國家利益豁免申請的要求。以我們處理過的NIW申請經驗來說，推薦信和申請書越有說服力，申請的通過率越高。</div>

          <div class="paragraph">問： 申請過程中個人技能和特殊背景的重要性有多大？</div>

          <div class="paragraph">答： 只強調申請人的個人技能和特殊背景是不夠的，而申請人的個人技能和特殊背景可以為美國帶來國家利益才是重要的，而且這種利益應該大於「保護美國工人的利益」這一國家利益。</div>

          <div class="paragraph">問： 在國家利益豁免申請中，誰負有舉證責任？</div>

          <div class="paragraph">答： 申請人及其律師負有舉證責任。</div>

          <div class="paragraph">問： 在讀博士生可以申請國家利益豁免嗎？</div>

          <div class="paragraph">答： 可以。因為國家利益豁免不需要雇主提供永久工作職位，所以只要滿足條件，在讀博士生完全可以申請國家利益豁免。</div>

          <div class="paragraph">問： 國家利益豁免對發表作品的數量的最低規定是多少？</div>

          <div class="paragraph">答： 移民局對此沒有特別規定。由美國移民局對不同的申請案作出不同的要求。當然，如果您發表的作品越多，對您的申請來說也越有利，獲得成功的機會就越高。</div>

          <div class="paragraph">問： 是否要證明在國家利益豁免申請人的工作領域內出現勞力短缺？</div>

          <div class="paragraph">答： 不需要。紐約州交通部案明確指出證明申請人工作領域內出現勞力短缺並不是國家利益豁免申請的考慮因素。並且新頒佈的關於審理國家利益豁免申請Dhanasar標準更是直接跳過了這個問題，因為NIW是直接豁免了EB-2申請所需的勞工證程式。</div>

          <div class="paragraph">問： 除了國家利益豁免，職業移民還有哪些類別是不需要事先獲得勞工證的？</div>

          <div class="paragraph">答： 還有職業移民第一優先EB-1。如果您符合職業移民第一優先的要求，就可以不用先申請並獲得勞工證。在職業移民第一優先中，如果您符合「傑出人才」EB-1(a)的要求，就不需要雇主提供永久工作職位</div>

          <div class="paragraph">問： 我必須在美國境內才能申請國家利益豁免嗎？</div>

          <div class="paragraph">答： 不需要。無論您是在美國還是在其他任何一個國家，您都可以申請國家利益豁免。</div>
          <div class="paragraph">問： 什麼是推薦信？</div>

          <div class="paragraph">答： 推薦信一般由申請人的工作領域或相關領域內的專家或權威學者出具的。內容主要表明國家利益豁免申請人的能力和成就，以及申請人的工作可以為美國的國家利益帶來的好處。在國家利益豁免申請中，推薦信的作用是非常重要的。</div>

          <div class="paragraph">問： 我應該找誰來為我出具推薦信呢？</div>

          <div class="paragraph">答： 獲得推薦信的管道有很多。一般說來申請人應該找自己的工作領域內的專家來寫推薦信，說明申請人在該領域做出過重要貢獻，也獲得了高度認可。其他管道就是曾經使用過申請人的工作成果的公司的高級領導人員，現任或者以前的雇主。我們還建議您應該獲得一些雖沒有和您一起工作過，但瞭解您在該領域的歷史成就的人士寫的推薦信，因為這樣的推薦信比由熟知您的人士寫的更有份量。</div>

          <div class="paragraph"> 其他管道就是曾經使用過申請人的工作成果的公司的高級領導人員，現任或者以前的雇主。我們還建議您應該獲得一些雖沒有和您一起工作過，但瞭解您在該領域的歷史成就的人士寫的推薦信，因為這樣的推薦信比由熟知您的人士寫的更有份量。</div>

          <div class="paragraph">問： 一個成功的國家利益豁免需要多少封推薦信？推薦信越多越好嗎？</div>

          <div class="paragraph">答： 不是。移民局並沒有規定推薦信的數量。移民局官員更看重的是內容而不是數量。</div>

          <div class="paragraph">問： 推薦信的說服力應該有多強？</div>

          <div class="paragraph">答： 當然是越強越好。推薦信最好能夠熱情讚美申請人的能力，天賦，做出的成就還有其工作將會給美國的國家利益帶來的有益影響。</div>


          <div class="paragraph">問：如果我的老闆不願意給我寫很有說服力的推薦信，那我的國家利益豁免申請還能成功嗎？</div>

          <div class="paragraph">答：申請人並非一定要在現任老闆處獲得推薦信才能使國家利益豁免申請成功。但如果老闆願意的話，申請人應該邀請其為自己出具推薦信。如果您的老闆不願意給你寫強有力的推薦信，您可以邀請自己工作領域內的著名專家為您提供推薦信，這樣申請也能通過。</div>

          <div class="paragraph">問： 藝術家或者音樂家可以申請國家利益豁免嗎？</div>

          <div class="paragraph">答： 沒有行業限制，只要符合標準就可以進行申請。</div>

          <div class="paragraph">問： 如果我沒有在自己的工作領域內的期刊上發表過文章，我還能申請國家利益豁免嗎？</div>

          <div class="paragraph">答： 當然可以。並沒有相關的規定要求申請人一定要發表過作品才能申請國家利益豁免，或者發表過作品的申請人才有可能會被批准。但是，如果您有發表過的作品，可以使您的申請更加有說服力。</div>

          <div class="paragraph">問： 如果我在自己的工作領域內沒有獲得過獎項，我還能申請國家利益豁免嗎？ </div>

          <div class="paragraph">答： 可以。在國家利益豁免的申請標準中，並沒有相關規定要求申請人一定要得過獎項才能申請國家利益豁免，或者得過獎項的申請人才有可能被批准。同樣，如果您得過相關獎項，越能增強您的申請的說服力，從而提高您的申請成功率。</div>

          <div class="paragraph">問：如果我不是自己的工作領域內的專業組織的會員，我還能申請國家利益豁免嗎？</div>

          <div class="paragraph">答： 可以。在國家利益豁免的申請標準中，沒有相關規定申請人必須是某些專業組織、協會或者學會等團體的會員才能申請國家利益豁免，或才有可能獲得批准。但是如果您具有相關的會員身份，可以使您的申請更加有說服力。</div>

          <div class="paragraph">問： 如果我目前不是自己的工作領域內的專業組織的會員，但是如果我交了一定的會員費就可以成為會員。我是否應該加入這個組織以提高我的國家利益豁免申請的成功率？ </div>

          <div class="paragraph">答： 不一定。如果您是一些只有著名或者權威人士才能加入的組織的會員，這對您的國家利益豁免申請很有幫助。但是如果這個組織可以很容易就加入，那麼是這種組織的會員對您的國家利益豁免申請並沒有什麼幫助。</div>

          <div class="paragraph">問： 我想通過職業移民第一優先EB-1來申請移民。我可以同時申請國家利益豁免嗎？ </div>

          <div class="paragraph">答： 可以。您可選擇的移民申請（I-140申請）類別並不僅限於一種。您可以同時進行多個類別的申請。這樣可以提高您的移民申請的成功機率，因為移民局會批准哪個申請是很難預料的。</div>

          <div class="paragraph">問： 如果我已經通過其他移民類別遞交了移民申請，我還可以申請國家利益豁免嗎？ </div>

          <div class="paragraph">答： 可以。在通過其他移民類別遞交了移民申請以後，您還可以遞交國家利益豁免申請，提高您的移民申請的成功率，因為移民局會批准哪個申請時很難預料的。如果您的另外一個申請被否決了，但是您的國家利益豁免申請還是有可能被批准。另外，有一些移民類別需要由雇主進行擔保申請，一旦這樣的申請獲得了批准，受益人就必須為這個雇主工作一段特定的時間。而國家利益豁免申請則不需要由雇主出面擔保，所以如果申請獲得了批准，申請人也沒有義務必須要為某個雇主工作。</div>

          <div class="paragraph">問： 如果我同時遞交了職業移民第一優先申請EB-1和國家利益豁免申請NIW，它們之間會互相產生不利的影響嗎？ </div>

          <div class="paragraph">答： 沒有。這兩類申請中間沒有任何聯繫，不會互相影響。</div>

          <div class="paragraph">問： 在國家利益豁免和職業移民第一優先中的「傑出技能外國人」EB-1(a)，哪一個更好？ </div>

          <div class="paragraph">答： 這兩種類別的情況不同。目前，移民局對於EB-1(a)的批准率總體來說並不高，但是如果您的申請準備充分，說服力很強，相比之下，申請EB-1(a)要更好一些。因為，目前EB-1(a)沒有排期。但是，如果您所能提供的材料說服力不夠，那麼NIW會是個不錯的選擇。當然，若條件比較模棱兩可，同時遞交國家利益豁免和EB-1(a)也是一種選擇，這樣既能用NIW保底，又能衝刺下EB1A，等結果出來了，再選擇一個類別去轉換申請即可。</div>

          <div class="paragraph">問：如果我的勞工證申請正在等待狀態，我是否可以同時申請國家利益豁免？</div>

          <div class="paragraph">答： 可以。勞工證和國家利益豁免是彼此獨立的申請，並沒有聯繫。勞工證申請是由美國勞工部處理的，而國家利益豁免由移民局處理。如果您的勞工證最後被拒絕了，您的國家利益豁免申請還是有被批准的可能性的。無論通過哪種類別，要拿到綠卡都要申請身份調整I-485或者申請移民簽證。如果勞工證獲得批准，那麼後來的移民申請也需要由同一個雇主出面進行擔保申請，在I-140獲得批准後受益人還必須為該雇主工作一段特定的時間。但是申請國家利益豁免，申請人沒有義務為某一特定雇主工作。</div>

          <div class="paragraph">問： 如果我的勞工證申請被拒絕了，我還能申請國家利益豁免嗎？ </div>

          <div class="paragraph">答： 可以的，如果您符合國家利益豁免申請的標準。國家利益豁免和勞工證申請的標準是非常不同的。勞工證的申請是基於美國缺少某特定職位合格的具有最低資格的美國工人，而國家利益豁免是由於申請人從事這項工作可以比由美國員工從事這項工作給美國的國家利益帶來更多的好處。比如，對於某一工作來說，美國有很多可以符合這項工作最低標準的員工，但是如果由您來從事這項工作，可以比美國員工做得更好。在這種情況下，您的勞工證有可能會被否決，但是國家利益豁免申請極有可能會被批准。</div>

          <div class="paragraph">問： 什麼是「補充材料通知（Request for Additional Evidence, or RFE）」？</div>

          <div class="paragraph">答： 有時候，移民局會認為申請國家利益豁免的申請人沒有足夠的證據可以證明他/她滿足國家利益豁免的要求。在這種情況下，移民局就會發出「補充材料通知」，要求申請人繼續遞交移民局認為申請人缺少的證明材料。再者，每一位移民官會如何審查自己手裏的申請是很難預料的，有些時候，甚至是準備非常充分，說服力極強的申請也會收到「補充材料通知」。即使在遇到「補充材料通知」的情況下，申請人如果能提交全面、符合要求的補充文件，申請人也能獲批的可能。</div>

          <div class="paragraph">問：什麼是優先日（Priority Date）？</div>

          <div class="paragraph">答：優先日就是移民局收到您的NIW申請材料的那一天。</div>

          <div class="paragraph">問：我目前是F-1身份，可以申請國家利益豁免嗎？</div>

          <div class="paragraph">答：可以，只要您符合國家利益豁免的條件就可以提出申請。由於國家利益豁免不需要由雇主提供永久工作職位，所以很多學生都可以申請。我們已經成功代理過很多在讀學生的國家利益豁免申請。</div>

          <div class="paragraph">問：我目前是J-1身份，並且受「回國服務兩年」的限制。我可以申請國家利益豁免嗎？</div>

          <div class="paragraph">答：可以。但是在您提交身份調整申請綠卡之前，您必須獲得J-1豁免或者滿足「回國服務兩年」的要求。</div>

          <div class="paragraph">問：我目前是J-1身份，並且受「回國服務兩年」的限制。如果我遞交了國家利益豁免申請並獲得了批准，我的「回國服務兩年」限制會被豁免嗎？</div>

          <div class="paragraph">答：不會。J-1豁免和國家利益豁免是完全不同概念的兩件事。J-1豁免是指申請要求豁免「回國服務兩年」的限制。國家利益豁免是指申請豁免雇主提供永久工作職位的要求和進行勞工證申請的要求。即使您的國家利益豁免申請獲得批准了，您仍然要受「回國服務兩年」的限制。只有您獲得了J-1豁免或者滿足「回國服務兩年」的要求，您才能進行身份調整或者申請移民簽證。</div>

          <div class="paragraph">問：如果我的國家利益豁免申請被批准了，我可以申請進行身份調整嗎？</div>

          <div class="paragraph">答：這要結合排期情況，若獲批時無排期或排期已經到了，那麼就可以申請進行身份調整。</div>

          <div class="paragraph">問：我可以同時遞交身份調整申請和國家利益豁免申請嗎？</div>

          <div class="paragraph">答：是的，只要您的排期為「當前」，就可以在遞交國家利益豁免移民申請（I-140）的同時遞交身份調整（I-485）的申請。這樣做的好處就是您獲得綠卡的速度會快一些。</div>


          <div class="paragraph">問：你們可以保證我的申請獲得成功嗎？</div>

          <div class="paragraph">答：不能，我們無法承諾您的申請一定會獲得成功。但是，我們會為您提供最優質的服務，盡我們最大的努力幫您準備好申請材料，爭取案子早日獲批。</div>

          <div class="paragraph">問：我的國家利益豁免被批准後，我還要為現在的雇主工作嗎？</div>

          <div class="paragraph">答：不用。只要是您自己獨立遞交的申請，您就不受要為現在的雇主工作的要求限制。</div>

          <div class="paragraph">問：我的國家利益豁免申請被批准後，我是否必須在進行申請時說明的工作領域內繼續工作？</div>

          <div class="paragraph">答：是的，您必須在進行申請時說明的工作領域內繼續工作，並為美國的國家利益帶來有益的影響。如果您改行進入了其他領域，移民局可能會拒絕您後續的身份調整申請（I-485），或者取消您的綠卡。</div>

          <div class="paragraph">問：國家利益豁免NIW和職業移民第一優先EB-1之間有什麼區別？</div>

          <div class="paragraph">答：雖然有資格申請EB-1(a)或EB-1(b)（EB-1(c)比較特殊）的人和有資格申請國家利益豁免的人之間可能會有許多相似之處，但是這兩類申請對申請人的要求卻是非常不同的。在國家利益豁免申請中，申請人首先要證明在自己的工作領域中是：「碩士或以上專業人員」或者是擁有「突出技能的外國人」，還要證明自己的工作與同領域的其他人員相比可以給美國的國家利益帶來更多的好處。能夠證明這些才能為豁免「由雇主提供永久工作職位」和「勞工證申請」找到理由。與此不同的是，滿足職業移民第一優先EB-1申請要求的申請人必須證明他/她在自己的工作領域中處於傑出或領先的地位。根據美國移民法，如果申請人在他/她自己的工作領域中處於傑出或領先的地位，在進行職業移民申請時就可以不必先申請勞工證。</div>

        `
      }
    ],
  },
  {
    title: 'EB-3 技術類人才',
    content:
    `
      <div class="paragraph">
        EB-3是美國職業移民第三優選類。 美國職業移民，是為了本國經濟利益而授予美國企業外籍員工永住權。需要美國雇主提供一個真實的永久性工作的承諾，並且證明沒有辦法招聘到合格的美國人擔任此份工作就可以獲得勞工卡，並申請移民。
      </div>

      <div class="paragraph">
        <p>EB-3申請條件：</p>
        <p>對於技術工人，需要至少2年的工作經驗，且從事的工作在美國找不到符合要求的美國工人；</p>
      </div>
      <div class="paragraph">
        對於專業人員，需證明擁有美國學士學位或是外國等同學位，並且從事的工作在美國找不到符合要求的美國人，教育和經驗不能抵消或等同學士學位；
      </div>
      <div class="paragraph">
        其他非技術性工人，需證明可以從事在美國長期缺乏符合條件的勞工的非技術性工作，通常需要不超過兩年的培訓或經驗 。
      </div>

      <div class="paragraph">
        <p>EB-3申請步驟：</p>
        <p>EB-3的申請者均需雇主申請勞工證（Labor Certificate），而勞工證的申請程式叫做PERM (Program Electronic Review Management Process)。整個PERM的申請過程主要考量的點在於：</p>
      </div>
      <div class="paragraph">
        在目前此職位的普遍工資下，雇傭不到符合此職位資質的美國工人
      </div>
      <div class="paragraph">
        雇傭此外國員工不會影響到同等被雇傭的美國員工的薪水和工作狀態
      </div>


      <div class="paragraph">
        PERM之中勞工證申請表（ETA FORM 9089）可以通過線上填寫或者是通過信件進行申請。從提交勞工證申請表之日算起，雇主必須保存勞工證的申請資料五年。PERM在被勞工部批准後，可遞交I-140綠卡申請。PERM的審理時間，如果沒有被勞工部核查，線上提交的話大概6個月會收到最終結果。信件提交的通常會更久。
      </div>
      <div class="paragraph">
        <p>永久性全職工作承諾：</p>
        <p>EB-3的申請者均需要雇主提供一個永久性全職工作的承諾，之後在勞工證得到批准後，才可以遞交申請。</p>
      </div>


      <div class="paragraph">
        排期：
        移民簽證的數量對於EB-3的影響是十分大的，當移民簽證申請數量飽和，簽證數額則會倒退，從而出現申請者無法申請，需要排期。通常情況下，EB-2的排期通常要短於EB-3，但是如果某年EB-2的申請者過多，也會出現其簽證數量倒退，從而比EB-3的排期時間還要長的情況。建議廣大勞工申請者在申請前到國家簽證中心提前瞭解一下簽證的發放情況以及排期情況後決定以哪種類別進行申請。
      </div>

    `
  },
  {
    title: 'EB-4 宗教等特殊職業',
    content:
    `
      <div class="paragraph">
        <p>宗教等特殊職業移民是職業移民第四類優先（EB-4）中的一種，又稱特殊移民簽證類別。若申請人所從事的職業比較特殊，比如宗教人士、美國武裝部隊成員等，則符合該申請類別的畫像。申請EB-4的多是全職牧師、神父以及一些宗教領域的神職人員。下麵我們針對神職人員的EB-4申請展開探討。</p>
        <p>在遞交I-360表格之前以及期間，外籍宗教人士無需在美國為潛在雇主工作。該外籍人士必須擁有2年在該教派工作的經驗，該教派須與潛在雇主所屬教派為同一教派， 但是這2年工作經驗無需從該潛在雇主處獲得。雖然外籍人士可以自己申請EB-4宗教人士移民，但大多數都是由潛在雇主替外籍人士進行申請。EB-4宗教人士移民申請中無須向勞工部申請勞工證。</p>
      </div>
      <div class="paragraph">
        <p>EB-4宗教人士移民的基本要求</p>
        <p>申請EB-4宗教人士移民時，雇主與該外籍員工都必須滿足一定要求。移民局一般會從下麵這幾個方面判斷雇主是否是真正的宗教機構以及該外籍人士到達美國之後是否會真正履行宗教職責。</p>
      </div>

      <div class="paragraph">
        <p>外籍人士來美之後從事的必須是全職工作（每週不少於35小時）</p>
        <p>外籍人士必須為在美國的真正的非營利性宗教組織工作</p>
        <p>外籍人士必須從事牧師級別職位或者其他宗教職位，包括專業與非專業職位</p>
        <p>外籍人士在遞交I-360表格之前的至少兩年內必須是雇主所屬教派的成員</p>
        <p>外籍人士在遞交I-360表格之前的至少兩年內必須從事牧師級別職位或者其他宗教職位，並且這些工作必須是在該外籍人士年滿14歲之後開始從事的。</p>
      </div>
      <div class="paragraph">
        <p>宗教人士的配偶及子女</p>
        <p>申請EB-4宗教人士移民的外籍人士配偶及21歲以下未成年子女可以一起申請移民美國或者調整身份。“兒童身份保護法案”同樣適用於EB-4宗教人士的子女。</p>
      </div>

    `
  },
  {
    title: 'EB-5 投資者移民',
    content:
    `
      <div class="paragraph">EB-5投資者移民簽證(又稱企業家簽證)。允許個人通過在美國進行較大數額投資的方式取得美國合法永久居留權，即“綠卡”。為了吸引外資，創造就業機會，刺激美國經濟增長，美國政府於1990 年在《美國移民歸化方案》中規定，個人投資者可以通過在美國投資一個新商業企業並為美國員工創造十個以上就業機會的方式取得美國永久居留 權。根據2022年3月頒佈的新法案，目標就業區(TEA)的投資金額為80萬美元。每年，美國政府根據EB-5計畫向投資者及其家庭提供約10,000個新的EB-5簽證。</div>

      <div class="paragraph">
        <p>EB-5申請條件：</p>
        <p>對申請人的資格條件比較寬鬆，沒有年齡，教育背景，語言能力等要求</p>
      </div>

      <div class="paragraph">申請人投資，全家移民。投資人及其配偶和未滿21歲的未婚子女都可以同時申請移民</div>

      <div class="paragraph">證明資金來源為合法取得</div>

      <div class="paragraph">EB-5申請步驟：</div>

      <div class="paragraph">遞交臨時綠卡申請（I-526）</div>

      <div class="paragraph">
        <p>獲得審批結果</p>
        <p>獲得I-526批准，等待排期，調整身份：申請人在美國（I-485），申請人在美國境外（國家簽證中心NVC）</p>
      </div>

      <div class="paragraph">獲得臨時綠卡（有效期為兩年）</div>

      <div class="paragraph">申請永久綠卡（I-829）</div>

      <div class="paragraph">拿到永久綠卡</div>

    `
  }
  
]
export const others = [
  {
    title: 'I-140獲批後，如何獲得美國綠卡?',
    content:
      `
        <div class="paragraph">簡單來說，美國綠卡的申請分為兩個階段，第一階段稱為移民申請，即遞交親屬移民I-130或職業移民I-140或I-526以及相應的申請材料。第二階段稱為身份調整，即將非移民身份（比如F-1，H-1，L-1等等）調整為移民身份（即綠卡）。因此，可以說，身份調整是整個綠卡申請的最後一步。這兩個階段在那些有排期的移民類別看來更加明顯。</div>
        <div class="paragraph">身份調整有兩種方式，若申請人在美國境內，那麼可以通過遞交I-485在美國轉換身份； 若根申請人在美國境外（比如在中國），則可以在美國境外完成領事館程式而獲得移民簽證並赴美取得綠卡。下麵我們來看看這兩種模式有什麼不同。</div>
        <div class="paragraph">其一，如果申請人在移民申請獲批後並且有移民簽證名額，申請人人在美國境內，他可以通過提交I-485表格進行身份調整。對於沒有排期的移民類別（比如EB-1以及有些國家的EB-2以及美國公民的直系親屬移民），如果申請人在美國境內申請是可以同時提交移民申請和I-485的。</div>
        <div class="paragraph">其二，尤其是對於有排期的移民類別，申請人在移民簽證有名額之前，很可能因為沒有合法的身份待在美國境內或因為其他事由離境美國或者在美國境外提交的移民申請，那麼他可以選擇完成領事館程式。對於中國申請人來說，就是在申請材料經過審批後到美國駐中國廣州總領事館進行面談獲得移民簽證。</div>
      `
  },
  {
    title: `Consular Processing 領事館程式`,
    children: [

      {
        title: `誰適合選擇領事館程式？`,
        content:
          `
            <div class="paragraph">
              <p>一般而言，申請人如果沒有長期居住在美國的需求或持有合法留美的簽證，我們通常建議選擇領事館程式，因為一旦面試通過，申請人就可以快速赴美領取綠卡，而境內轉換身份通常要等待比較久。且在等待期間，申請人若想出境，還需要提前申請，獲得美國的許可，否則有可能被判定放棄了身份調整，導致前功盡棄。</p>
            </div>
            <div class="paragraph">
              <p>領事館程式的優點</p>
              <p>首先，不是所有的美簽都適合或允許申請人選擇在美國境內走I-485，例如持有旅遊簽證的申請人在美國停留的前90天無法遞交I-485申請，否則會被移民局認為故意造假。</p>
            </div>
            <div class="paragraph">
              而且， 一旦通過領事館程式獲得綠卡申請批准，外籍人士就獲得了美國永久居民身份。領事館處理程式相比身份調整申請最大的一個優點是處理時間短。領事館處理程式一般只需要5到13個月，而調整身份申請的處理一般需要1到2年。
            </div>
            <div class="paragraph">
              <p>申請流程</p>
              <p>第一步 如果申請人在遞交移民時選擇了領事館程式，移民局會將移民申請的批准通知（I-797）寄往美國國家簽證中心（NVC）。如果當時申請人沒有表明會進行領事館移民簽證程式，申請人則需要先提交I-824表格要求進行領事館移民簽證程式。</p>
              <p>第二步 美國國家簽證中心會在有相應的移民簽證名額之後通知申請人繳納移民簽證費並提交相應的資料。</p>
              <p></p>
              <p>第三步 申請人按要求繳納移民簽證費和提交相應的資料給NVC。</p>
              <p>第四步 NVC確認文件並將文件移交給美國領事館（廣領館）並通知申請人出席面試。</p>
              <p>第五步 申請人完成移民體檢和準備好面談文件，並如期出席面試。</p>
              <p>第六步 面試通過後，獲得貼簽護照。</p>
              <p>第七步 申請人登陸美國，領取綠卡。</p>
            </div>
            <div class="paragraph">
              <p>準備材料</p>
              <p>收到NVC通知時，申請人除了需要繳納移民簽證費、填寫DS-260表格外，還需要準備好一些文件。以下為常見材料，具體材料清單還要結合申請人的自身情況。</p>
              <p>● 移民申請批復通知（I-797）;</p>
              <p>● 公證書（出生公證、無犯罪公證等）；</p>
              <p>● 護照；</p>
              <p>● 服役記錄；</p>
              <p>● 翻譯件 所有中文材料需要附有英文翻譯件；</p>
              <p>● 體檢報告（ 接到面試通知後面試前，申請人需到指定的醫院完成體檢）;</p>
            </div>
          `
      },
      {
       title: `孩子已經或者即將超過21周歲怎麼辦，還能一起拿綠卡嗎？`,
       content:
       `
        <div class="paragraph">
          根據移民法的規定，“兒童”指21歲以下並且未婚的子女。過去，如果兒童在移民申請或者調整身份申請期間年滿21周歲，這些兒童將失去通過當前申請獲得綠卡的資格，此類情況稱為“超齡” 。“超齡”的兒童將無法享受很多未滿21歲兒童可以享受的移民利益。近年來，由於移民申請人數激增等種種因素，美國移民案件積壓問題嚴重，很多申請人難免會面臨著孩子“超齡”問題。但值得注意的是，“超齡”並不意味著您的孩子喪失了獲得美國綠卡的機會。
        </div>
        <div class="paragraph">
          美國移民法中有專門保護孩子超齡的規定——《兒童身份保護法》（Child Status Protection Act，簡稱“CSPA”)。由於CSPA的規定比較複雜，絕大多數申請人都無法理解其適用範圍，更不懂如何通過運用CSPA提供的公式去計算孩子是否會超齡。所以，有可能會錯失了讓孩子一起拿綠卡的機會，如果您也有這種擔憂，歡迎聯繫我們，我們會根據結合您的情況及時答疑解惑，為您提供最優方案，以助您和孩子能一同獲得美國綠卡。
        </div>
       `
      },
      {
        title: `和領事館程式有關的常見問答`,
        content:
        `
          <div class="paragraph">
            問: 領事館移民簽證程式從何時開始？
          </div>
          <div class="paragraph">
            答: 在移民申請批准並且移民簽證有名額之後。
          </div>
          <div class="paragraph">
            問: 誰需要進行領事館移民簽證程式？
          </div>
          <div class="paragraph">
            答: 以下申請人可能需要進行領事館移民簽證程式：
          </div>
          <div class="paragraph">
            身處美國境外；或者雖然身處美國境內，但由於其他原因希望在境外進行領事館移民簽證程式
          </div>
          <div class="paragraph">
            問: 領事館移民簽證程式會在哪個國家進行？
          </div>
          <div class="paragraph">
            答: 移民受益人最後居住地的國家或其出生國。
          </div>
          <div class="paragraph">
            問: 如果移民受益人在美國，他可以同時申請I-485身份調整和領事館移民簽證程式嗎？
          </div>
          <div class="paragraph">
            答: 不能，只能選擇其中一個。
          </div>
          <div class="paragraph">
            問: 申請領事館移民簽證程式時能同時申請工卡EAD和回美證AP嗎？
          </div>
          <div class="paragraph">
            答: 不能。
          </div>
          <div class="paragraph">
            問: 如果移民受益人在美國境外，他可以選擇領事館移民簽證程式或I-485身份調整嗎？
          </div>
          <div class="paragraph">
            答: I-485申請要求申請人必須處於美國境內，因此他只能申請領事館移民簽證程式。
          </div>
          <div class="paragraph">
            問: 如果該移民受益人以合法的身份待在美國境內，在他的移民申請批准後，他可以任意選擇I-485身份調整或領事館移民簽證程式嗎？
          </div>
          <div class="paragraph">
            答: 是的，他可以選擇其一進行申請。
          </div>
          <div class="paragraph">
            問: 中國大陸地區在何處進行領事館移民簽證程式？
          </div>
          <div class="paragraph">
            答: 美國駐廣州總領事館。
          </div>
          <div class="paragraph">
            問: 如果移民受益人的NIW批准後，他的H-1B身份即將失效，他應該選擇I-485身份調整還是領事館移民簽證程式呢？
          </div>
          <div class="paragraph">
            答: 他可以任選其一，若選擇I-485，則需要再H-1B失效前遞交I-485。I-485提交後，他會獲得I-485 pending的身份合法居留美國。
          </div>
        `
      }
    ]
  },
  {
    title: `Adjustment of status 美國境內身份轉換`,
    children: [
      {
        title: `美國境內身份轉換的條件與優勢`,
        content:
        `
          <div class="paragraph">
            身份調整是身在美國的申請人由非移民身份轉向移民身份或永久居民身份時所提交的申請。要提交身份調整的申請，申請人不但需要滿足身份調整所要求的條件，還不能在法律上有任何導致不能提交身份調整申請的問題。
          </div>
          <div class="paragraph">
            要滿足身份調整的資格，申請人必須滿足以下條件：
          </div>
          <div class="paragraph">
            <p>1 申請人必須身在美國。 首先，提交 I-485 的申請人，在提交申請之日之前必須身在美國。如果申請人身在美國之外，他就不能申請I485身份調整了，而是需要在美國境外的領事館申請進行領事館程式。</p>
            <p>申請人的移民申請必須已經獲得了批准。 如果申請所在的類別（比如如EB-1A）有移民簽證名額可用，I-485身份調整申請能夠和I-140同時提交。</p>
          </div>
          <div class="paragraph">
            2 移民簽證名額對於申請人必須可用。 除了美國公民的直系親屬外，家庭移民申請的其他種類以及職業移民申請都要受每年移民簽證的名額數限制。這些申請人，只有在等到移民簽證名額可用的情況下，才能提交他們的I-485身份調整申請。
          </div>
          <div class="paragraph">
            3 申請人必須是合法入境。 外籍者在進入美國時必須經過檢查才會被認為是合法入境。移民局認為「經過檢查」也就說在入境口岸經過了移民官員的檢查。在移民官員告訴你可以入境並且允許你入境之後，也就意味著外籍者已經合法入境。一般來說，簽證上的I-94表格或移民章都標誌著持有者為合法入境。
          </div>
          <div class="paragraph">
            <p>4 與移民申請相關的條件沒有改變。 相關條件的變化有可能會給身份調整申請帶來不利的影響。舉個例子：申請人A先生早前NIW申請已經獲批，隨後A先生在美國遞交了I-485身份調整。但在申請批准之前，A先生不再原來的領域工作了，而是跨行到了新的領域工作。</p>
            <p>這樣，因為他的工作領域有所變更，他的身份調整申請就有可能被拒。</p>
          </div>
          <div class="paragraph">
            然而，並不是說滿足以上的條件就自然能夠獲得身份調整申請的批准。申請人如果出現以下情況，將不會被允許在美國調整身份。這些情況包括：
          </div>

          <div class="paragraph">非法工作，身份非法或者不能維持合法身份 －－ 申請人如果有非法工作的狀況，或者在提交申請時申請人處於非法身份或者在進入美國之後申請人曾經哪怕有一天處於非法身份狀態，他的I -485身份調整申請都有可能被拒。</div>

          <div class="paragraph">J簽證的交流訪問學者 －－J-1 或J-2 非移民簽證的持有者因為有兩年的回國限制，在沒有完成這兩年回國居住的限制並且也沒有獲得豁免的情況下，就不能順利進行身份調整。 </div>

          <div class="paragraph">未婚夫/妻K簽證 －－持有K-1 簽證的美國公民的未婚夫/妻只有在一種情況下才可以申請I-485身份調整，這就是說只有當K-1簽證的持有者與他所持簽證的申請人按照簽證的相關規定在進入美國90天內成婚後，才可以申請I-485身份調整。也就是說，如果K-1簽證持有者和另外一個美國公民而非他/她申請簽證時的未婚夫/妻結婚的話，他/她同樣不能申請身份調整。</div>

          <div class="paragraph">社會負擔 －－任何身份調整申請的申請人都必須證明他們或者他們申請的支持者(例如配偶)有足夠的經濟能力保障。申請人如果不能證明自己不會成為社會負擔，他/她的身份調整申請將不能獲得批准。</div>

          <div class="paragraph">處於遣送程式但與美國公民或綠卡持有者結婚的外籍者 －－在這種情況下，如果申請人僅以獲得綠卡為目的而結婚，則申請人的調整身份不能被批准。但是，如果申請人能證明婚姻的締結是出於善意，並不是只為了獲得綠卡，他/她的身份調整就應該能夠獲得批准。</div>

          <div class="paragraph">通過簽證豁免計畫入境的外籍者 －－通過移民法案217條規定的免簽證計畫或212(1)條規定的關島免簽證計畫以旅遊或商務目的進入美國的外籍者，不得申請I-485身份調整。</div>

          <div class="paragraph">D簽證持有者 －－持D簽證的外國國際航班的機組成員以及輪船上的海員在達到美國的時候不能進行I-485身份調整。</div>

          <div class="paragraph">無簽證過境 －－以第三國為目的地的無簽證從美國過境的外籍者，不能申請身份調整。</div>

          <div class="paragraph">身份調整申請的不確定性 我們要記住非常重要的一點，那就是I-485身份調整申請的批准與否是有不確定性的。也就是說，即使一位申請人完全符合身份調整所需要的條件並且也沒有任何違犯身份調整相關規定的禁止狀況，他/她的申請仍舊有可能被移民局拒絕。</div>

        `,
      },
    ]
  }
]