<template>
  <div class="navbar-view">
    <div class="navbar-container">
      <div class="navbar-left">
        <img class="navbar-logo" src="../assets/img_logo.png" @click="handleTo('home')" alt="logo">
      </div>
      <div class="navbar-right">
        <div :class="`navbar-item ${routerName == item.name ? 'active-item' : ''}`" v-for="(item,index) in navList" :key="index" @click="handleTo(item.name)">
          {{item.title}}
          <div class="children-view" v-if="item.name === 'consult'">
            <p class="children-item" @click.stop="handleToConsult('nonimmigrant')">非移民簽證</p>
            <p class="children-item" @click.stop="handleToConsult('immigrant')">移民簽證</p>
            <p class="children-item" @click.stop="handleToConsult('others')">其他</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>

import { useRouter } from 'vue-router';
import { useI18n } from "vue-i18n";
import { computed, ref } from 'vue';

const router = useRouter();

const { t } = useI18n();

const routerName = computed(() => {
  return router.currentRoute.value.name;
})

const navList = ref([
  {
    title: t('navBar.title1'),
    name: 'home'

  },
  {
    title: t('navBar.title2'),
    name: 'about'
  },
  {
    title: t('navBar.title3'),
    name: 'consult'
  },
  {
    title: t('navBar.title6'),
    name: 'hk'
  },
  {
    title: t('navBar.title4'),
    name: 'contact'
  },
  {
    title: t('navBar.title5'),
    name: 'schedule'
  }
]);


const handleTo = (name) => {
  if (name == 'hk') {
    window.open('https://www.taoyuanintl.com/','_bank')
    return
  }
  router.push({ name: name })
};

const handleToConsult = (type) => {
  router.replace(`/consult/${type}`)
};


</script>
<style lang="scss" scoped>
.navbar-view {
  display: flex;
  justify-content: center;
  background-color: #FFF;
  .navbar-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1300px;
    height: 50px;
    .navbar-logo{
      cursor: pointer;
      height: 20px;
    }
    .navbar-right{
      display: flex;
      align-items: center;
      font-weight: 400;
      .navbar-item{
        cursor: pointer;
        margin-right: 60px;
        line-height: 50px;
        position: relative;
        .children-view{
          display: none;
          position: absolute;
          left: -30px;
          bottom: -150px;
          white-space: nowrap;
          background: #FFF;
          font-weight: 400;
          .children-item{
            color: #000000;
            padding: 0 23px;
          }
          .children-item:hover{
            color: #FFFFFF;
            font-weight: 700;
            background: #FF5B33;
          }
        }
      }
      .navbar-item:last-child{
        margin-right: 0px;
      }
      .navbar-item:hover{
        color: #FF5B33;
        font-weight: 700;
        .children-view{
          display: block;
        }
      }
      .active-item{
        color: #FF5B33;
        font-weight: 700;
      }
    }
  }
}


</style>
